<template>
  <v-text-field
    v-model="formattedParentValue"
    class="mb-2 mt-2 "
    :label="label"
    readonly
    disabled
  >
    <template v-slot:prepend-inner>
      <!-- hack to add a inner padding before the textfield... Needed to have the content in line with the
           regular v-text-field with solo=true, but we can't use "solo" here, as we need the label! -->
      <div class="text-field-padding"></div>
    </template>

    <template v-slot:append-outer>
      <div v-if="value">
        <v-tooltip top open-delay="500">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs" v-on="on"
              color="red darken-2"
              @click="uncheck()"
            >
              link
            </v-icon>
          </template>
          <span>Daten werden von Eltern-Einschränkung übernommen</span>
        </v-tooltip>
      </div>

      <div v-if="!value">
        <v-tooltip top open-delay="500">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs" v-on="on"
              @click="check()"
            >
              link_off
            </v-icon>
          </template>
          <span>Daten werden NICHT von Eltern-Einschränkung übernommen</span>
        </v-tooltip>
      </div>
    </template>
  </v-text-field>
</template>

<script>

export default {
  name: 'EntryRestrictionEditParentTextField',
  props: {
    value: {
      type: Boolean
    },
    parentValue: {},
    isDate: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    // if copy is active at mountpoint of this component, we automaticly
    // emit a copy-event, so that the system will use the most current value
    // on save
    if (this.value) {
      this.$emit('copy', true)
    }
  },
  computed: {
    label() {
      if (!this.parentValue) {
        return 'Kein Wert vorhanden'
      }

      return this.$t('main.VALUE_OF_PARENT')
    },
    formattedParentValue() {
      if (this.isDate) {
        return this.formatDate(this.parentValue)
      }
      return this.parentValue
    }
  },
  methods: {
    check() {
      this.$emit('input', true)
      this.$emit('copy', true)
    },
    uncheck() {
      this.$emit('input', false)
    },
    formatDate: function(date) {
      if (!date) {
        return null
      }

      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
  },
  watch: {
    value() {
      if (this.value) {
        this.check()
      }
    }
  }
}

</script>

<style scoped>
  .text-field-padding {
    width: 7px;
  }
</style>