<template>
  <div class="main-view">
    <v-row v-if="entryRestrictionWithParent && entryRestrictionWithParent.data && entryRestriction">
      <v-col><h1 class="headline">Reiseeinschränkungen</h1>{{ getEntryRestrictionGeoDescription(entryRestriction) }}</v-col>
      <v-col>
        <div>
          Zuletzt bearbeitet:
          <span v-if="entryRestriction.modifiedAt">{{ formatDateTime(entryRestriction.modifiedAt) }}, {{ getModifiedByName(entryRestriction) }}</span>
          <span v-if="!entryRestriction.modifiedAt">Noch nicht gespeichert</span>
        </div>
        <div>
          Zuletzt geprüft:
          <span v-if="entryRestriction.checkedAt">{{ formatDateTime(entryRestriction.modifiedAt) }}, {{ getCheckedByName(entryRestriction) }}</span>
          <span v-if="!entryRestriction.checkedAt">Noch nicht geprüft</span>
        </div>
      </v-col>
      <v-col>
        <div>
          Wichtige Änderung:
          <span v-if="entryRestriction.significantUpdateAt">
            {{ formatDateTime(entryRestriction.significantUpdateAt) }}, {{ getSignificantUpdateByName(entryRestriction) }}, , {{ entryRestriction.significantUpdateNote }}
          </span>
          <span v-if="!entryRestriction.significantUpdateAt">Noch keine Wichtige Änderung</span>
        </div>
      </v-col>
      <v-col><v-btn class="refresButton" @click="doEntryRestrictionFetch(true)"><v-icon class="mr-2">refresh</v-icon>Neu laden</v-btn></v-col>
    </v-row>

    <div v-if="entryRestrictionWithParent && entryRestrictionWithParent.data">
      <EntryRestrictionEdit
        :height="editorHeight"
        :entryRestriction="entryRestriction"
        :entryRestrictionParent="entryRestrictionParent"
        :entryRestrictionAuthorities="entryRestrictionAuthorities"
        @save="saveEntryRestriction"
        @cancel="cancel"
      />
    </div>

  </div>
</template>

<script>
import { mapGetters, mapState, mapActions, mapMutations } from "vuex"
import moment from "moment"
import EntryRestrictionEdit from "@/components/entryrestriction/edit/EntryRestrictionEdit";

export default {
  name: 'EntryRestrictionEditView',
  data() {
    return {
      window: {
        width: 0,
        height: 0
      }
    }
  },
  components: {
    EntryRestrictionEdit
  },
  mounted: function() {
    this.doEntryRestrictionAuthoritiesFetchAll(false)
    this.doEntryRestrictionFetch(true)
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  computed: {
    ...mapState({
      entryRestrictionAuthorities: state => state.entryRestrictionAuthoritiesStore.entries,
      entryRestrictionAuthoritiesIsDone: state => state.entryRestrictionAuthoritiesStore.isDone,
      entryRestrictionAuthoritiesIsLoading: state => state.entryRestrictionAuthoritiesStore.isLoading,
    }),
    ...mapGetters({
      entryRestrictionWithParentStoreGetById: 'entryRestrictionWithParentStore/getById'
    }),
    entryRestrictionWithParent() {
      return this.entryRestrictionWithParentStoreGetById(this.$route.params.entryRestrictionId)
    },
    entryRestriction() {
      if (this.entryRestrictionWithParent) {
        return this.entryRestrictionWithParent.data.entryrestriction
      }
      return null
    },
    entryRestrictionParent() {
      if (this.entryRestrictionWithParent) {
        return this.entryRestrictionWithParent.data.entryrestrictionParent
      }
      return null
    },
    editorHeight() {
      return (this.window.height - 230) + 'px'
    }
  },
  methods: {
    ...mapActions({
      entryRestrictionAuthoritiesFetchAll: 'entryRestrictionAuthoritiesStore/fetchAll',
      entryRestrictionWithParentFetch: 'entryRestrictionWithParentStore/fetch',
      entryRestrictionUpdate: 'entryRestrictionStore/update',
      gmEventCovidUpdateCreate: 'gmEventCovidUpdateStore/create',   
    }),
    ...mapMutations({
      entryRestrictionClear: 'entryRestrictionStore/clear',
    }),
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    doEntryRestrictionFetch(force) {
      this.entryRestrictionWithParentFetch({ variables: { id: this.$route.params.entryRestrictionId }, force: force })
    },
    doEntryRestrictionAuthoritiesFetchAll(force) {
      this.entryRestrictionAuthoritiesFetchAll({ force: force })
    },
    saveEntryRestriction(saveData) {

      let entryRestriction = saveData.entryRestriction
      let gmEventCovidUpdate = saveData.gmEventCovidUpdate

      function getIdOrNull(ob) {
        if (ob && ob.id) {
          return ob.id
        }
        return null
      }

      let entryRestrictionInput = {
        id: entryRestriction.id,
        reminder: entryRestriction.reminder,
        restrictionLevel: entryRestriction.restrictionLevel,
        isActive: true,
        internalNote: entryRestriction.internalNote,
        internalSources: entryRestriction.internalSources,
        regionDeviationTourism: entryRestriction.regionDeviationTourism,
        regionDeviationBusiness: entryRestriction.regionDeviationBusiness,
        significantUpdateAt: entryRestriction.significantUpdateAt,
        significantUpdateNoteDe: entryRestriction.significantUpdateNoteDe,
        significantUpdateNoteEn: entryRestriction.significantUpdateNoteEn,
        significantUpdateBy: getIdOrNull(entryRestriction.significantUpdateBy),
        checkedAt: entryRestriction.checkedAt,
        checkedBy: getIdOrNull(entryRestriction.checkedBy),
        country: getIdOrNull(entryRestriction.country),
        region: getIdOrNull(entryRestriction.region),
        airport: getIdOrNull(entryRestriction.airport),
        entryrestrictiondetails: []
      }
      for (let entryrestrictionsdetail of entryRestriction.entryrestrictiondetails) {
        let editCategories = []
        for (let editCategory of entryrestrictionsdetail.editCategories) {
          editCategories.push({
            category: editCategory.category,
            copyFromBusiness: editCategory.copyFromBusiness
          })
        }


        entryRestrictionInput.entryrestrictiondetails.push({
          authority: entryrestrictionsdetail.authority.id,
          restrictionType: entryrestrictionsdetail.restrictionType,
          noAccessEnd: entryrestrictionsdetail.noAccessEnd,
          noAccessStart: entryrestrictionsdetail.noAccessStart,
          noRestrictionsStart: entryrestrictionsdetail.noRestrictionsStart,
          noRestrictionsEnd: entryrestrictionsdetail.noRestrictionsEnd,
          someRestrictionsStart: entryrestrictionsdetail.someRestrictionsStart,
          someRestrictionsEnd: entryrestrictionsdetail.someRestrictionsEnd,
          sourceUri: entryrestrictionsdetail.sourceUri,
          note: entryrestrictionsdetail.noteDe,
          noteDe: entryrestrictionsdetail.noteDe,
          noteEn: entryrestrictionsdetail.noteEn,
          noteRaw: entryrestrictionsdetail.noteRawDe,
          noteRawDe: entryrestrictionsdetail.noteRawDe,
          noteRawEn: entryrestrictionsdetail.noteRawEn,
          noAccessStartSignificantUpdateAt: entryrestrictionsdetail.noAccessStartSignificantUpdateAt,
          noAccessEndSignificantUpdateAt: entryrestrictionsdetail.noAccessEndSignificantUpdateAt,
          noRestrictionsStartSignificantUpdateAt: entryrestrictionsdetail.noRestrictionsStartSignificantUpdateAt,
          noRestrictionsEndSignificantUpdateAt: entryrestrictionsdetail.noRestrictionsEndSignificantUpdateAt,
          someRestrictionsStartSignificantUpdateAt: entryrestrictionsdetail.someRestrictionsStartSignificantUpdateAt,
          someRestrictionsEndSignificantUpdateAt: entryrestrictionsdetail.someRestrictionsEndSignificantUpdateAt,
          noteSignificantUpdateAt: entryrestrictionsdetail.noteSignificantUpdateAt,
          restrictionDetailJson: entryrestrictionsdetail.restrictionDetailJson,
          copyBase: entryrestrictionsdetail.copyBase,
          significantUpdateAt: entryrestrictionsdetail.significantUpdateAt,
          sourceUriCopyParent: entryrestrictionsdetail.sourceUriCopyParent,
          noRestrictionsStartCopyParent: entryrestrictionsdetail.noRestrictionsStartCopyParent,
          noRestrictionsEndCopyParent: entryrestrictionsdetail.noRestrictionsEndCopyParent,
          noAccessStartCopyParent: entryrestrictionsdetail.noAccessStartCopyParent,
          noAccessEndCopyParent: entryrestrictionsdetail.noAccessEndCopyParent,
          someRestrictionsStartCopyParent: entryrestrictionsdetail.someRestrictionsStartCopyParent,
          someRestrictionsEndCopyParent: entryrestrictionsdetail.someRestrictionsEndCopyParent,
          noteRawCopyParent: entryrestrictionsdetail.noteRawCopyParent,
          editCategories: editCategories
        })
      }

      let gmEventCovidUpdateInput = null
      if (gmEventCovidUpdate) {
        gmEventCovidUpdateInput = {
          country: getIdOrNull(entryRestriction.country),
          textDe: gmEventCovidUpdate.textDe,
          textEn: gmEventCovidUpdate.textEn,
          authorityDe: gmEventCovidUpdate.authorityDe,
          authorityEn: gmEventCovidUpdate.authorityEn,
          riskLevel: gmEventCovidUpdate.riskLevel,
          sources: gmEventCovidUpdate.sources
        }
      }

      this.entryRestrictionUpdate({
        variables: {
          'input': entryRestrictionInput
        }
      }).then(() => {
        
        if (gmEventCovidUpdateInput) {
          this.gmEventCovidUpdateCreate({
            variables: {
              'input': gmEventCovidUpdateInput
            }
          }).then(() => {
            this.entryRestrictionClear()
            this.$router.push({ name: 'entryrestrictions' })
          })
          .catch((error) => {
            alert("Fehler")
          })
        } else {
          this.entryRestrictionClear()
          this.$router.push({ name: 'entryrestrictions' })
        }

      }).catch((error) => {
        alert("Fehler")
      })


      /*
      this.entryRestrictionUpdate({
        variables: {
          'input': entryRestrictionInput
        }
      })
      .then(() => {
        this.gmEventCovidUpdateCreate({
          variables: {
            'input': gmEventCovidUpdateInput
          }
        }).then(() => {
          this.entryRestrictionClear()
          this.$router.push({ name: 'entryrestrictions' })
        })
        .catch((error) => {
          alert("Fehler")
        })
      })
      .catch((error) => {
        alert("Fehler")
      })
      */
    },
    cancel() {
      // when we leave an edit page with cancel, the system still has the changes version of the entry in its store
      // to prevent any further usage, we clear the store and require a reload
      if (this.entryRestriction) {
        this.entryRestrictionClear()
      }

      // redirect to list
      this.$router.push({ name: 'entryrestrictions' })
    },

    getEntryRestrictionGeoDescription(entryRestriction) {
      let geo = []
      if (entryRestriction.country) {
        geo.push(entryRestriction.country.nameShort)
      }
      if (entryRestriction.region) {
        geo.push(entryRestriction.region.name)
      }
      if (entryRestriction.airport) {
        geo.push(entryRestriction.airport.iata)
      }

      return geo.join(', ')
    },

    formatDateTime(dateTime) {
      let momentDate = moment(dateTime)
      if (!momentDate.isValid()) {
        return ''
      }
      return momentDate.format('DD.MM.YYYY HH:mm')
    },

    getModifiedByName(entryRestriction) {
      if (!entryRestriction.modifiedBy) {
        return ''
      }
      return [entryRestriction.modifiedBy.firstName, entryRestriction.modifiedBy.lastName].join(' ')
    },

    getCheckedByName(entryRestriction) {
      if (!entryRestriction.checkedBy) {
        return ''
      }
      return [entryRestriction.checkedBy.firstName, entryRestriction.checkedBy.lastName].join(' ')
    },

    getSignificantUpdateByName(entryRestriction) {
      if (!entryRestriction.significantUpdateBy) {
        return ''
      }

      return [entryRestriction.significantUpdateBy.firstName, entryRestriction.significantUpdateBy.lastName].join(' ')
    },
  },
  watch: {
    '$route.params.entryRestrictionId': function (id) {
      this.doEntryRestrictionFetch(true)
    }
  }
}
</script>

<style scoped>
  .main-view {
    padding: 0rem 1rem;
    max-width: 2600px;
    margin: auto;
  }

  .loadingBlock {
    margin-top: 0.5rem;
    height: 0.5rem;
    clear: both;
  }

  .refresButton {
    float: right;
  }
</style>
