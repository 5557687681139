<template>
  <div v-if="entryRestriction">

    <v-simple-table fixed-header :height="height" class="entryRestrictionTable" light>
      <template v-slot:default>
        <thead>
          <tr class="header-1">
            <th style="width: 120px">
              <v-btn
                v-if="hasParent"
                color="secondary"
                small
                @click="copyAllFromParent"
              >
                Von Eltern übernehmen
              </v-btn>
            </th>
            <th v-for="(name, restrictionType) in restrictionTypes" :key="restrictionType" colspan="2" class="multi-column">
              <v-row>
                <v-col>{{ name }}</v-col>
                <v-col>
                  <v-checkbox
                    v-if="restrictionType == 'TOURISM'"
                    v-model="localEntryRestriction.regionDeviationTourism"
                    class="mt-0 pt-0"
                    label="Region: Teilreisewarnung"
                  />
                  <v-checkbox
                    v-if="restrictionType == 'BUSINESS'"
                    v-model="localEntryRestriction.regionDeviationBusiness"
                    class="mt-0 pt-0"
                    label="Region: Teilreisewarnung"
                  />
                </v-col>
              </v-row>
            </th>
          </tr>
          <tr class="header-2">
            <th>Feld</th>
            <th
              v-for="entryRestrictionDetail in entryRestrictionDetails"
              :key="entryRestrictionDetail.id"
              v-bind:class="{ tourismCol: entryRestrictionDetail.restrictionType == 'TOURISM' }"
            >
              {{ entryRestrictionDetail.authority.name }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>Basisdaten kopieren</th>
            <td
              v-for="entryRestrictionDetail in entryRestrictionDetails"
              :key="'row1_'+entryRestrictionDetail.id"
              v-bind:class="{ tourismCol: entryRestrictionDetail.restrictionType == 'TOURISM' }"
            >
              <div v-if="entryRestrictionDetail.restrictionType == 'TOURISM'">
                <v-btn style="float: right" color="secondary" x-small v-if="!entryRestrictionDetail.copyBase" @click="applyFromBusiness(entryRestrictionDetail)">Übernehmen</v-btn>
                <v-checkbox v-model="entryRestrictionDetail.copyBase" label="Dauerhaft kopieren"></v-checkbox>
              </div>
            </td>
          </tr>
          <tr>
            <th>{{ $t("main.SOURCE") }}</th>
            <td
              v-for="entryRestrictionDetail in entryRestrictionDetails"
              :key="'row1_'+entryRestrictionDetail.id" class="input-content"
              v-bind:class="{ tourismCol: entryRestrictionDetail.restrictionType == 'TOURISM' }"
            >
              <div v-if="!entryRestrictionDetail.copyBase">
                <!-- parent field -->
                <EntryRestrictionEditParentTextField
                  v-if="hasParent"
                  v-model="entryRestrictionDetail.sourceUriCopyParent"
                  :parentValue="getParentDetail(entryRestrictionDetail).sourceUri"
                  @copy="changeCopyParentValue(entryRestrictionDetail, ['sourceUri'])"
                />

                <!-- entryRestrictionDetail field -->
                <v-text-field
                  v-model="entryRestrictionDetail.sourceUri"
                  v-bind:class="{ 'cell-main-input': hasParent, 'field-changed': entryRestrictionDetail.sourceUri != entryRestrictionDetail.sourceUriOriginal }"
                  append-icon="open_in_new"
                  @click:append="openLink(entryRestrictionDetail.sourceUri)"
                  solo
                  dense
                  :readonly="entryRestrictionDetail.sourceUriCopyParent"
                  :disabled="entryRestrictionDetail.sourceUriCopyParent"
                />
              </div>
            </td>
          </tr>
          <tr>
            <th>{{ $t("main.REGULAR_CONDITIONS") }}</th>
            <td
              v-for="entryRestrictionDetail in entryRestrictionDetails"
              :key="'row2_'+entryRestrictionDetail.id"
              v-bind:class="{ tourismCol: entryRestrictionDetail.restrictionType == 'TOURISM' }"
            >
              <v-row>
                <v-col>
                  <div v-if="!entryRestrictionDetail.copyBase">
                    <!-- parent field -->
                    <EntryRestrictionEditParentTextField
                      v-if="hasParent"
                      v-model="entryRestrictionDetail.noRestrictionsStartCopyParent"
                      :parentValue="getParentDetail(entryRestrictionDetail).noRestrictionsStart"
                      :is-date="true"
                      @copy="changeCopyParentValue(entryRestrictionDetail, ['noRestrictionsStart'])"
                    />

                    <!-- entryRestrictionDetail field -->
                    <div v-bind:class="{ 'cell-main-input': hasParent }">
                      <v-icon v-if="entryRestrictionDetail.noRestrictionsIsValid === false" color="red">error</v-icon>

                      <DatePicker
                        v-model="entryRestrictionDetail.noRestrictionsStart"
                        v-bind:class="{ 'field-changed': entryRestrictionDetail.noRestrictionsStart != entryRestrictionDetail.noRestrictionsStartOriginal }"
                        label=""
                        clearable
                        dense
                        solo
                        :readonly="entryRestrictionDetail.noRestrictionsStartCopyParent"
                        :disabled="entryRestrictionDetail.noRestrictionsStartCopyParent"
                      />
                    </div>
                  </div>
                </v-col>
                <v-col>
                  <div v-if="!entryRestrictionDetail.copyBase">
                    <!-- parent field -->
                    <EntryRestrictionEditParentTextField
                      v-if="hasParent"
                      v-model="entryRestrictionDetail.noRestrictionsEndCopyParent"
                      :parentValue="getParentDetail(entryRestrictionDetail).noRestrictionsEnd"
                      :is-date="true"
                      @copy="changeCopyParentValue(entryRestrictionDetail, ['noRestrictionsEnd'])"
                    />

                    <!-- entryRestrictionDetail field -->
                    <div v-bind:class="{ 'cell-main-input': hasParent }">
                      <v-icon v-if="entryRestrictionDetail.noRestrictionsIsValid === false" color="red">error</v-icon>

                      <DatePicker
                        v-model="entryRestrictionDetail.noRestrictionsEnd"
                        v-bind:class="{ 'field-changed': entryRestrictionDetail.noRestrictionsEnd != entryRestrictionDetail.noRestrictionsEndOriginal }"
                        label=""
                        clearable
                        dense
                        solo
                        :readonly="entryRestrictionDetail.noRestrictionsEndCopyParent"
                        :disabled="entryRestrictionDetail.noRestrictionsEndCopyParent"
                      />
                    </div>
                  </div>
                </v-col>
              </v-row>
            </td>
          </tr>
          <tr>
            <th>{{ $t("main.NO_ACCESS_POSSIBLE") }}</th>
            <td
              v-for="entryRestrictionDetail in entryRestrictionDetails"
              :key="'row4_'+entryRestrictionDetail.id"
              v-bind:class="{ tourismCol: entryRestrictionDetail.restrictionType == 'TOURISM' }"
            >
              <v-row>
                <v-col>
                  <div v-if="!entryRestrictionDetail.copyBase">
                    <!-- parent field -->
                    <EntryRestrictionEditParentTextField
                      v-if="hasParent"
                      v-model="entryRestrictionDetail.noAccessStartCopyParent"
                      :parentValue="getParentDetail(entryRestrictionDetail).noAccessStart"
                      :is-date="true"
                      @copy="changeCopyParentValue(entryRestrictionDetail, ['noAccessStart'])"
                    />

                    <!-- entryRestrictionDetail field -->


                    <div v-bind:class="{ 'cell-main-input': hasParent }">
                      <v-icon v-if="entryRestrictionDetail.noAccessIsValid === false" color="red">error</v-icon>

                      <DatePicker
                        v-model="entryRestrictionDetail.noAccessStart"
                        v-bind:class="{ 'field-changed': entryRestrictionDetail.noAccessStart != entryRestrictionDetail.noAccessStartOriginal }"
                        label=""
                        clearable
                        dense
                        solo
                        :readonly="entryRestrictionDetail.noAccessStartCopyParent"
                        :disabled="entryRestrictionDetail.noAccessStartCopyParent"
                      />
                    </div>
                  </div>
                </v-col>
                <v-col>
                  <div v-if="!entryRestrictionDetail.copyBase">
                    <!-- parent field -->
                    <EntryRestrictionEditParentTextField
                      v-if="hasParent"
                      v-model="entryRestrictionDetail.noAccessEndCopyParent"
                      :parentValue="getParentDetail(entryRestrictionDetail).noAccessEnd"
                      :is-date="true"
                      @copy="changeCopyParentValue(entryRestrictionDetail, ['noAccessEnd'])"
                    />

                    <!-- entryRestrictionDetail field -->
                    <div v-bind:class="{ 'cell-main-input': hasParent }">
                      <v-icon v-if="entryRestrictionDetail.noAccessIsValid === false" color="red">error</v-icon>

                      <DatePicker
                        v-model="entryRestrictionDetail.noAccessEnd"
                        v-bind:class="{ 'field-changed': entryRestrictionDetail.noAccessEnd != entryRestrictionDetail.noAccessEndOriginal }"
                        label=""
                        clearable
                        dense
                        solo
                        :readonly="entryRestrictionDetail.noAccessEndCopyParent"
                        :disabled="entryRestrictionDetail.noAccessEndCopyParent"
                      />
                    </div>
                  </div>
                </v-col>
              </v-row>
            </td>
          </tr>
          <tr>
            <th>{{ $t("main.PARTIAL_RESTRICTIONS") }}</th>
            <td
              v-for="entryRestrictionDetail in entryRestrictionDetails"
              :key="'row6_'+entryRestrictionDetail.id"
              v-bind:class="{ tourismCol: entryRestrictionDetail.restrictionType == 'TOURISM' }"
            >
              <v-row>
                <v-col>
                  <div v-if="!entryRestrictionDetail.copyBase">
                    <!-- parent field -->
                    <EntryRestrictionEditParentTextField
                      v-if="hasParent"
                      v-model="entryRestrictionDetail.someRestrictionsStartCopyParent"
                      :parentValue="getParentDetail(entryRestrictionDetail).someRestrictionsStart"
                      :is-date="true"
                      @copy="changeCopyParentValue(entryRestrictionDetail, ['someRestrictionsStart'])"
                    />

                    <!-- entryRestrictionDetail field -->
                    <div v-bind:class="{ 'cell-main-input': hasParent }">
                      <v-icon v-if="entryRestrictionDetail.someRestrictionsIsValid === false" color="red">error</v-icon>

                      <DatePicker
                        v-model="entryRestrictionDetail.someRestrictionsStart"
                        v-bind:class="{ 'field-changed': entryRestrictionDetail.someRestrictionsStart != entryRestrictionDetail.someRestrictionsStartOriginal }"
                        clearable
                        label=""
                        dense
                        solo
                        :readonly="entryRestrictionDetail.someRestrictionsStartCopyParent"
                        :disabled="entryRestrictionDetail.someRestrictionsStartCopyParent"
                      />
                    </div>
                  </div>
                </v-col>
                <v-col>
                  <div v-if="!entryRestrictionDetail.copyBase">
                    <!-- parent field -->
                    <EntryRestrictionEditParentTextField
                      v-if="hasParent"
                      v-model="entryRestrictionDetail.someRestrictionsEndCopyParent"
                      :parentValue="getParentDetail(entryRestrictionDetail).someRestrictionsEnd"
                      :is-date="true"
                      @copy="changeCopyParentValue(entryRestrictionDetail, ['someRestrictionsEnd'])"
                    />

                    <!-- entryRestrictionDetail field -->
                    <div v-bind:class="{ 'cell-main-input': hasParent }">
                      <v-icon v-if="entryRestrictionDetail.someRestrictionsIsValid === false" color="red">error</v-icon>

                      <DatePicker
                        v-model="entryRestrictionDetail.someRestrictionsEnd"
                        v-bind:class="{ 'field-changed': entryRestrictionDetail.someRestrictionsEnd != entryRestrictionDetail.someRestrictionsEndOriginal }"
                        clearable
                        label=""
                        dense
                        solo
                        :readonly="entryRestrictionDetail.someRestrictionsEndCopyParent"
                        :disabled="entryRestrictionDetail.someRestrictionsEndCopyParent"
                      />
                    </div>
                  </div>
                </v-col>
              </v-row>
            </td>
          </tr>
          <tr>
            <td class="sub-level">{{ $t("main.FREETEXT") }}</td>
            <td
              v-for="entryRestrictionDetail in entryRestrictionDetails"
              :key="'row8_'+entryRestrictionDetail.id"
              v-bind:class="{ tourismCol: entryRestrictionDetail.restrictionType == 'TOURISM' }"
            >
              <div v-if="entryRestrictionDetail.authority.authorityType != 'DESTCOUNTRY'" >
                <div v-if="!entryRestrictionDetail.copyBase">
                <!-- parent field -->
                <EntryRestrictionEditParentTextField
                  v-if="hasParent"
                  v-model="entryRestrictionDetail.noteRawCopyParent"
                  :parentValue="getParentDetail(entryRestrictionDetail).noteRawDe"
                  @copy="changeCopyParentValue(entryRestrictionDetail, ['noteRawDe', 'noteRawEn'])"
                />

                <!-- entryRestrictionDetail field -->
                  <div v-bind:class="{ 'cell-main-input': hasParent }">
                    <TranslatableHtmlTextFieldWithDialog
                      v-if="!entryRestrictionDetail.copyBase"
                      v-bind:class="{ 'field-changed': entryRestrictionDetail.noteRawDe != entryRestrictionDetail.noteRawDeOriginal || entryRestrictionDetail.noteRawEn != entryRestrictionDetail.noteRawEnOriginal }"
                      :de="entryRestrictionDetail.noteRawDe"
                      :en="entryRestrictionDetail.noteRawEn"
                      v-on:input-de="entryRestrictionDetail.noteRawDe = $event"
                      v-on:input-en="entryRestrictionDetail.noteRawEn = $event"
                      dense
                      solo
                      :readonly="entryRestrictionDetail.noteRawCopyParent"
                      :disabled="entryRestrictionDetail.noteRawCopyParent"
                    />
                  </div>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <th class="sub-level">Ampel</th>
            <td v-for="entryRestrictionDetail in entryRestrictionDetails" :key="'row8_'+entryRestrictionDetail.id" v-bind:class="{ tourismCol: entryRestrictionDetail.restrictionType == 'TOURISM' }"></td>
          </tr>
          <tr>
            <td class="sub-level-2">Ampel kopieren</td>
            <td
              v-for="entryRestrictionDetail in entryRestrictionDetails"
              :key="'row1_'+entryRestrictionDetail.id"
              v-bind:class="{ tourismCol: entryRestrictionDetail.restrictionType == 'TOURISM' }"
            >
              <div v-if="entryRestrictionDetail.restrictionType == 'TOURISM' && entryRestrictionDetail.authority.authorityType == 'DESTCOUNTRY'">
                <v-btn color="secondary" x-small style="margin-right: 1rem;" @click="applyDetailsFromBusiness(entryRestrictionDetail)">Übernehmen</v-btn>
                <v-btn x-small color="secondary" @click="copyAllTourismDetailsFromBusiness(entryRestrictionDetail)">Alle von Geschäftsreisen kopieren</v-btn>
              </div>
            </td>
          </tr>

          <template v-for="(typeCategoryEntry, typeCategory) in detailTypesByCategory">

            <tr :key="typeCategory" :style="{ backgroundColor: typeCategoryEntry.data.backgroundColor, color: typeCategoryEntry.data.color }">
              <td></td>
              <td
                v-for="entryRestrictionDetail in entryRestrictionDetails"
                :key="'row_9_'+entryRestrictionDetail.id"
              >
                <div
                  v-if="entryRestrictionDetail.authority.authorityType == 'GERMANFOREIGNOFFICE' && entryRestrictionDetail.restrictionType == 'BUSINESS'"
                  style="text-align: right"
                >
                  <b>{{ typeCategoryEntry.data.title }}</b>
                </div>

                <div
                  v-if="entryRestrictionDetail.authority.authorityType == 'DESTCOUNTRY' && entryRestrictionDetail.restrictionType == 'TOURISM'"
                  style="text-align: right"
                >
                  
                  <template v-for="category in entryRestrictionDetail.editCategories">
                    <div v-if="category.category == typeCategory" :key="category.category">
                      <v-checkbox
                        v-model="category.copyFromBusiness" 
                        v-if="typeCategoryEntry.data.allowCopyFromBusiness"
                        label="Von Geschäftsreisen kopieren"
                        style="text-shadow: 0 0 white"
                      />
                    </div>
                  </template>

                </div>
                
              </td>
            </tr>

            <tr v-for="(detailTypeTranslation, detailType) in typeCategoryEntry.types" :key="detailType">
              <td class="sub-level-2"></td>
              <td
                v-for="entryRestrictionDetail in entryRestrictionDetails"
                :key="'row10_'+entryRestrictionDetail.id"
                v-bind:class="{ tourismCol: entryRestrictionDetail.restrictionType == 'TOURISM', isCidmer: detailTypeTranslation.isCidmer }"
              >
                <!-- Show the detail name in the GERMANFOREIGNOFFICE + BUSINESS Column, as it is closer to the first content column.
                      Makes it easier to read for the user! -->
                <div
                  v-if="entryRestrictionDetail.authority.authorityType == 'GERMANFOREIGNOFFICE' && entryRestrictionDetail.restrictionType == 'BUSINESS'"
                  style="text-align: right"
                >
                  {{ detailTypeTranslation.name }}
                </div>

                <div v-if="!isEditCategoryCopy(entryRestrictionDetail, typeCategory) && entryRestrictionDetail.authority.authorityType == 'DESTCOUNTRY'" >
                  <div v-for="detail in entryRestrictionDetail.restrictionDetails.entries" :key="detail.type">
                    <template v-if="detail.type == detailType && ((detailTypeTranslation.showBusiness && entryRestrictionDetail.restrictionType == 'BUSINESS') || entryRestrictionDetail.restrictionType == 'TOURISM')">
                      <EntryRestrictionEditParentTextField
                        v-if="hasParent"
                        v-model="detail.copyParent"
                        :parentValue="getDetailParentValuePreview(entryRestrictionDetail, detail, detailTypeTranslation.type)"
                        @copy="changeCopyParentDetailValue(entryRestrictionDetail, detail, ['status', 'noteRawDe', 'noteRawEn', 'value'])"
                      />

                      <v-row dense>
                        <v-col v-if="detailTypeTranslation.type != 'FREETEXT' && detailTypeTranslation.type != 'POSINT'">
                          <v-radio-group
                            v-if="detailTypeTranslation.type == 'YESNO' || detailTypeTranslation.type == 'YESNOTEXT'"
                            class="ma-0 pa-0"
                            v-bind:class="{ 'field-changed': detail.status != detail.statusOriginal }"
                            v-model="detail.status"
                            :readonly="detail.copyParent"
                            :disabled="detail.copyParent"
                            row
                            dense
                            hide-details
                          >
                            <v-radio label="Nein" value="NO" />
                            <v-radio label="Ja" value="YES" />
                            <v-radio label="Unbek." value="UNKNOWN" />
                          </v-radio-group>
                          <v-select
                            v-if="detailTypeTranslation.type == 'COLOR'"
                            :items="detailTypeColor"
                            v-model="detail.status"
                            v-bind:class="{ 'field-changed': detail.status != detail.statusOriginal }"
                            label=""
                            dense
                            solo
                            :readonly="detail.copyParent"
                            :disabled="detail.copyParent"
                          >
                            <template v-slot:item="{ item }">
                              <span v-if="item.value == 'RED'"    class="color-select-item"  style="background-color: red;" />
                              <span v-if="item.value == 'YELLOW'" class="color-select-item"  style="background-color: yellow;" />
                              <span v-if="item.value == 'BLUE'"   class="color-select-item"  style="background-color: blue;" />
                              <span v-if="item.value == 'GRAY'"   class="color-select-item"  style="background-color: gray;" />
                              <span>{{ item.text }}</span>
                            </template>
                            <template v-slot:selection="{ item }">
                              <span v-if="item.value == 'RED'"    class="color-select-item"  style="background-color: red;" />
                              <span v-if="item.value == 'YELLOW'" class="color-select-item"  style="background-color: yellow;" />
                              <span v-if="item.value == 'BLUE'"   class="color-select-item"  style="background-color: blue;" />
                              <span v-if="item.value == 'GRAY'"   class="color-select-item"  style="background-color: gray;" />
                              <span>{{ item.text }}</span>
                            </template>
                          </v-select>
                        </v-col>
                        <v-col v-if="detailTypeTranslation.type == 'FREETEXT' || detailTypeTranslation.type == 'YESNOTEXT' || detailTypeTranslation.type == 'COLOR' || detailTypeTranslation.type == 'POSINT'">
                          <TranslatableHtmlTextFieldWithDialog
                            v-if="detailTypeTranslation.type == 'FREETEXT' || detailTypeTranslation.type == 'YESNOTEXT' || detailTypeTranslation.type == 'COLOR'"
                            :de="detail.noteRawDe"
                            :en="detail.noteRawEn"
                            v-bind:class="{ 'field-changed': detail.noteRawDe != detail.noteRawDeOriginal || detail.noteRawEn != detail.noteRawEnOriginal }"
                            v-on:input-de="detail.noteRawDe = $event;detail.noteRaw=$event"
                            v-on:input-en="detail.noteRawEn = $event"
                            dense
                            solo
                            :readonly="detail.copyParent"
                            :disabled="detail.copyParent"
                            />
                          <v-text-field
                            v-if="detailTypeTranslation.type == 'POSINT'"
                            type="number"
                            min="0"
                            v-model="detail.value"
                            v-bind:class="{ 'field-changed': detail.value != detail.valueOriginal }"
                            dense
                            solo
                            :readonly="detail.copyParent"
                            :disabled="detail.copyParent"
                          />
                        </v-col>
                      </v-row>
                    </template>
                  </div>
                </div>
              </td>
            </tr>
          </template> <!-- detail type group -->

        </tbody>
      </template>
    </v-simple-table>

    <v-row class="ml-1 mr-1">
      <DatePicker v-model="localEntryRestriction.reminder" label="Erinnerung" clearable class="col-2" />
      <span class="col-2" style="margin-top: -24px"><TextFieldWithDialog  class="ml-4" v-model="localEntryRestriction.internalNote" label="Interne Notiz" /></span>
      <span class="col-3" style="margin-top: -24px"><TextFieldWithDialog  class="ml-4" v-model="localEntryRestriction.internalSources" label="Interne Quellen" /></span>
      <span v-if="entryRestriction && entryRestrictionAuthorities && localEntryRestriction" class="ml-4 mt-4">
        <v-btn color="primary" @click.stop="openSaveDialog">Speichern</v-btn>
        <v-btn color="primary" class="ml-4" @click="checked">Geprüft</v-btn>
      </span>
      <v-btn color="secondary" class="ml-4 mt-4" @click="cancel">Abbrechen</v-btn>
    </v-row>

    <v-dialog v-model="saveDialog">
      <v-card style="min-width: 1000px; max-width: 1400">
        <v-card-title class="headline">
          <v-tabs
            v-model="activeSaveTab"
          >
            <v-tab>Wichtige Änderungen</v-tab>
            <v-tab>GM Update</v-tab>
          </v-tabs>
        </v-card-title>

        <v-card-text>
          <v-tabs-items v-model="activeSaveTab">
            <v-tab-item>
              Bitte markiere Änderungen, die als "Wichtige Änderungen" gespeichert werden sollen.
              <v-data-table
                v-model="saveSignificantUpdatesSelected"
                height="600"
                fixed-header
                show-select
                hide-default-footer
                :headers="saveHeaders"
                :items="saveItems"
                :items-per-page="-1"
                item-key="id"
                group-by="cat"
                class="elevation-1"
                show-group-by
              ></v-data-table>
            </v-tab-item>
            <v-tab-item>
              <div v-if="!entryRestriction.country.idExternalGmEvent">
                Dieses Land verfügt über kein GM-Event in der Datenbank.
              </div>

              <div v-if="entryRestriction.country.idExternalGmEvent">
                <v-row>
                  <v-col cols="auto">
                    <v-checkbox
                      v-model="gmEventCovidUpdate.send"
                      class="pa-0 ma-0"
                      label="GM Event Update senden"
                      dense
                      hide-details
                    />
                  </v-col>
                  <v-col v-if="entryRestriction.country.urlGmEvent" cols="auto">
                    <a :href="entryRestriction.country.urlGmEvent" target="_blank">GM Event öffnen</a>
                  </v-col>
                </v-row>
                
                <div v-if="gmEventCovidUpdate.send">

 
                  <v-row>
                    <v-col>
                      <v-select
                        v-model="gmEventCovidUpdate.riskLevel"
                        :items="gmEventCovidUpdate.riskLevels"
                        label="Risk-Level"
                        hide-details
                      />
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="gmEventCovidUpdate.authorityDe"
                        label="Authority (DE)"
                        hide-details
                      />
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="gmEventCovidUpdate.authorityEn"
                        label="Authority (EN)"
                        hide-details
                      />
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      Deutsch:
                      <v-textarea v-model="gmEventCovidUpdate.textDe" solo />
                    </v-col>
                    <v-col cols="auto">
                      <v-btn
                        color="secondary"
                        class="ml-5 mb-2 mt-8"
                        small
                        :loading="isTranslating"
                        @click="translateGmEventTextToEnglish"
                      >
                        DE -&gt; EN
                      </v-btn>
                    </v-col>
                    <v-col>
                      English:
                      <v-textarea v-model="gmEventCovidUpdate.textEn" solo />
                    </v-col>
                  </v-row>

                  <v-divider class="my-4 mx-8"/>

                  Quellen:
                  <div v-for="(source, index) in gmEventCovidUpdate.sources" :key="index">
                    <v-row>
                      <v-col>
                        <v-text-field
                          v-model="source.sourceDe"
                          label="Quelle (DE)"
                          hide-details
                          dense
                        />
                      </v-col>
                      <v-col>
                        <v-text-field
                          v-model="source.sourceEn"
                          label="Quelle (EN)"
                          hide-details
                          dense
                        />
                      </v-col>
                      <v-col>
                        <v-text-field
                          v-model="source.link"
                          label="Quelle-Link"
                          hide-details
                          dense
                        />
                      </v-col>
                    </v-row>  

                    <v-row>
                      <v-col>
                        <v-text-field
                          v-model="source.descriptionDe"
                          label="Beschreibung (DE)"
                          hide-details
                          dense
                        />
                      </v-col>
                      <v-col>
                        <v-text-field
                          v-model="source.descriptionEn"
                          label="Beschreibung (EN)"
                          hide-details
                          dense
                        />
                      </v-col>
                      <v-col cols="auto">
                        <v-btn @click="removeGmEventCovidUpdateSource(index)">Quelle entfernen</v-btn>
                      </v-col>
                    </v-row>

                    <v-divider class="my-4 mx-8"/>
                  </div>
                  <v-btn @click="addGmEventCovidUpdateSource">Quelle hinzufügen</v-btn>
                </div>
              </div>

            </v-tab-item>
          </v-tabs-items>
        </v-card-text>          
        <v-card-actions>
          <v-spacer v-if="!saveSignificantUpdatesSelected.length" />
          <v-text-field
              v-if="saveSignificantUpdatesSelected.length"
              v-model="significantUpdateNoteDe"
              counter="200"
              label="(Deutsch) Kurzbeschreibung der Änderung"
              :rules="rules.maxLength200"
              class="mr-6"
            />
          <v-text-field
              v-if="saveSignificantUpdatesSelected.length"
              v-model="significantUpdateNoteEn"
              counter="200"
              label="(English) Kurzbeschreibung der Änderung"
              :rules="rules.maxLength200"
              class="mr-6"
            />
          <v-btn
            v-if="saveSignificantUpdatesSelected.length"
            color="primary"
            :disabled="saveItems.length == 0"
            @click="save"
          >
            Als wichtige Änderung speichern
          </v-btn>
          <v-btn
            v-if="!saveSignificantUpdatesSelected.length"
            color="primary"
            @click="save"
          >
            <span v-if="!gmEventCovidUpdate.send">Speichern!</span>
            <span v-if="gmEventCovidUpdate.send">Speichern &amp; GM Update senden!</span>
          </v-btn>
          <v-btn color="secondary" @click="saveDialog = false">Abbrechen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>

import { mapState, mapGetters } from "vuex"
import moment from "moment"
import DatePicker from "@/components/ui/DatePicker";
import EntryRestrictionEditParentTextField from "@/components/entryrestriction/edit/EntryRestrictionEditParentTextField";
import TextFieldWithDialog from "@/components/ui/TextFieldWithDialog";
import TranslatableHtmlTextFieldWithDialog from "@/components/ui/TranslatableHtmlTextFieldWithDialog";
import coreService from '@/services/CoreService'

export default {
  name: 'EntryRestrictionEdit',
  components: {
    DatePicker,
    TextFieldWithDialog,
    TranslatableHtmlTextFieldWithDialog,
    EntryRestrictionEditParentTextField
  },
  props: {
    entryRestriction: {
      type: Object
    },
    entryRestrictionParent: {
      type: Object
    },
    entryRestrictionAuthorities: {
      type: Array
    },
    height: {
      type: String,
      default: '500px'
    }
  },
  data() {
    return {
      isTranslating: false,
      activeSaveTab: 0,
      localEntryRestriction: {},
      localEntryRestrictionParent: {},
      localDataProcessed: false,
      gmEventCovidUpdate: {
        send: false,
        riskLevels: [
          { value: null, text: "Nicht ändern" },
          { value: 1, text: "1: Sehr gering" },
          { value: 2, text: "2: Gering" },
          { value: 3, text: "3: Erhöht" },
          { value: 4, text: "4: Hoch" },
          { value: 5, text: "5: Sehr hoch" },
        ],
        textDe: '',
        textEn: '',
        authorityDe: '',
        authorityEn: '',
        riskLevel: null,
        sources: []
      },
      restrictionTypes: {
        BUSINESS: 'Geschäftsreisen',
        TOURISM: 'Privatreisen',
      },
      detailTypeYesNo: [
        {"value": "YES", "text": "Ja"},
        {"value": "NO", "text": "Nein"},
      ],
      detailTypeColor: [
        {"value": "BLUE", "text": "Blau"},
        {"value": "RED", "text": "Rot"},
        {"value": "YELLOW", "text": "Gelb"},
        {"value": "GRAY", "text": "Grau"},
      ],
      saveDialog: false,

      //significantUpdateIsChecked: false, // a significant update was done as part of a full check
      significantUpdateNoteDe: '', // a note saved alongside the update (DE)
      significantUpdateNoteEn: '', // a note saved alongside the update (EN)

      rules: {
        maxLength200: [
          (v) => !!v || "Required",
          (v) => (v && v.length <= 200) || "200 Chars max",
        ]
      },

      saveSignificantUpdatesSelected: [],

      saveHeaders: [
          { text: 'Kategorie', align: 'start', value: 'cat'},
          { text: 'Feld', align: 'start', value: 'fieldName', groupable: false, },
          { text: 'Neu', align: 'start', value: 'new', groupable: false, },
          { text: 'Alt', align: 'start', value: 'old', groupable: false, },
        ],

      saveItems: [],
    }
  },
  mounted() {
    this.updateLocalEntryRestrictionParent()
    this.updateLocalEntryRestriction()

    this.localDataProcessed = true
  },
  computed: {
    ...mapState({
      user: state => state.authStore.user,
      detailTypes: state => state.entryRestrictionDetailTypes,
      detailTypeCategories: state => state.entryRestrictionDetailTypesCategories,
    }),
    ...mapGetters({
      detailTypesByCategory: 'entryRestrictionDetailTypesByCategory',
    }),
    entryRestrictionDetails() {
      if (!this.localEntryRestriction || !this.localEntryRestriction.entryrestrictiondetails || !this.entryRestrictionAuthorities) {
        return []
      }

      return this.localEntryRestriction.entryrestrictiondetails
    },
    hasParent() {
      return !!this.entryRestrictionParent
    },

  },
  methods: {
    processLocalEntryRestriction(entryRestriction) {
      if (!entryRestriction || !entryRestriction.entryrestrictiondetails || !this.entryRestrictionAuthorities) {
        return;
      }

      let localEntryRestriction = { ... entryRestriction }

      /* We must have one EntryRestrictionDetail per Authority. If none exists in the entryRestriction, we create it empty */
      let originalDetails = entryRestriction.entryrestrictiondetails
      let entryRestrictionDetails = []

      let newId = 0

      for (let restrictionType of Object.entries(this.restrictionTypes)) {
        for (let authority of this.entryRestrictionAuthorities) {
          newId++

          // find matching entryRestrictionDetail
          let entryRestrictionDetail = originalDetails.find(entry => entry.authority.id == authority.id && entry.restrictionType == restrictionType[0])

          let editCategories = this.buildDefaultEditCategories()

          // not found? Create an empty one
          if (!entryRestrictionDetail) {
            entryRestrictionDetail = {
              id: 'NEW_' + newId,
              authority: authority,
              sourceUri: '',
              note: '',
              noteDe: '',
              noteEn: '',
              noteRaw: '',
              noteRawEn: '',
              noteRawDe: '',
              noRestrictionsStart: null,
              noRestrictionsEnd: null,
              someRestrictionsStart: null,
              someRestrictionsEnd: null,
              noAccessStart: null,
              noAccessEnd: null,
              restrictionType: restrictionType[0],
              restrictionDetails: {'entries': []},
              copyBase: false,
              sourceUriCopyParent: false,
              noRestrictionsStartCopyParent: false,
              noRestrictionsEndCopyParent: false,
              noAccessStartCopyParent: false,
              noAccessEndCopyParent: false,
              someRestrictionsStartCopyParent: false,
              someRestrictionsEndCopyParent: false,
              noteRawCopyParent: false,
              editCategories: editCategories
            }
          } else {
            if (entryRestrictionDetail.restrictionDetailJson != null && entryRestrictionDetail.restrictionDetailJson != '') {
              // we must process the restriction_detail_json and make it usable for out form
              let restrictionDetail = JSON.parse(entryRestrictionDetail.restrictionDetailJson)
              this.$set(entryRestrictionDetail, "restrictionDetails", restrictionDetail)
            } else {
              this.$set(entryRestrictionDetail, "restrictionDetails", {'entries': []})
            }
          }

          /* copy every field and create *Original fields, like sourceUriOriginal.
             Used to mark changed fields in the UI */
          for (let [field, value] of Object.entries(entryRestrictionDetail)) {
            this.$set(entryRestrictionDetail, field+'Original', value)
          }

          // we must also ensure that all required detailTypes are available, or the Table could not find the detailType
          // while looping through the restrictionDetails. This would result in missing input fields
          let newEntries = []
          for (let detailType of Object.entries(this.detailTypes)) {
            let detailEntry = entryRestrictionDetail.restrictionDetails.entries.find(detail => detail['type'] == detailType[0])
            if (!detailEntry) {
              detailEntry = {
                type: detailType[0],
                status: '',
                value: "",
                note: '',
                noteDe: '',
                noteEn: '',
                noteRaw: '',
                noteRawDe: '',
                noteRawEn: '',
                copyParent: false,
              }
            }

            // Find again, as we might have added the entry this time.
            // Add the *riginal fields for Status and Note fields!
            for (let field of ['status', 'noteRawDe', 'noteRawEn', 'value']) {
              detailEntry[field+'Original'] = detailEntry[field]
            }

            newEntries.push(detailEntry)
          }
          entryRestrictionDetail.restrictionDetails.entries = newEntries

          entryRestrictionDetails.push(entryRestrictionDetail)
        }
      }

      localEntryRestriction.entryrestrictiondetails = entryRestrictionDetails

      return localEntryRestriction
    },
    buildDefaultEditCategories() {
      let editCategories = []
      for (const [detailTypeCategory, data] of Object.entries(this.detailTypeCategories)) {
        editCategories.push({
          category: detailTypeCategory,
          copyFromBusiness: false
        })
      }
      return editCategories
    },
    updateLocalEntryRestriction() {
      this.localEntryRestriction = this.processLocalEntryRestriction(this.entryRestriction)

      this.$nextTick(() => {
        if (this.localEntryRestriction) {
          this.validate()
        }
      })
    },
    updateLocalEntryRestrictionParent() {
      this.localEntryRestrictionParent = this.processLocalEntryRestriction(this.entryRestrictionParent)
    },
    openLink(url) {
      window.open(url)
    },
    validateDates() {
      let isValid = true
      const dateFieldChecks = [
        {
          validField: 'noRestrictionsIsValid',
          startField: 'noRestrictionsStart',
          endField: 'noRestrictionsEnd',
        }, {
          validField: 'noAccessIsValid',
          startField: 'noAccessStart',
          endField: 'noAccessEnd',
        }, {
          validField: 'someRestrictionsIsValid',
          startField: 'someRestrictionsStart',
          endField: 'someRestrictionsEnd',
        }
      ]

      for (let entryrestrictiondetail of this.localEntryRestriction.entryrestrictiondetails) {

        // we do not validate entries that are copied on server side later, as this would
        // raise errors the user can not (and doesn't need to...) fix
        if (entryrestrictiondetail.copyBase) {
          continue
        }

        for (let dateFieldCheck of dateFieldChecks) {
          let startDate = entryrestrictiondetail[dateFieldCheck['startField']]
          let endDate = entryrestrictiondetail[dateFieldCheck['endField']]

          this.$set(entryrestrictiondetail, dateFieldCheck.validField, true)

          // We allow one or both fields to be empty. One field set is fine, as it
          // means that the start is unknown or we can't foresee the end of an event
          if (startDate == '' || endDate == '' || startDate == null || endDate == null) {
            continue
          }

          if (moment(endDate).isBefore(moment(startDate))) {
            isValid = false
            this.$set(entryrestrictiondetail, dateFieldCheck.validField, false)
          }
        }
      }

      return isValid
    },
    validate() {
      let isValid = this.validateDates()
      if (!isValid) {
        alert("Die Eingaben sind ungültig!")
        return false
      }

      return true
    },
    emitSave(isChecked, isSignificantUpdate, significantUpdateNoteDe, significantUpdateNoteEn ) {
      let nowAsIso = moment().format()

      let dateFields = [
        'noRestrictionsStart',
        'noRestrictionsEnd',
        'noAccessStart',
        'noAccessEnd',
        'someRestrictionsStart',
        'someRestrictionsEnd',
      ]

      // before we send the same entry, we must transform the restrictionDetails back to its json form
      let entryRestriction = this.localEntryRestriction
      for (let entryRestrictionDetail of entryRestriction.entryrestrictiondetails) {

        // set default values
        for (const dateField of dateFields) {
          let fieldName = dateField+'SignificantUpdateAt'
          if (!(fieldName in entryRestrictionDetail)) {
            entryRestrictionDetail[dateField+'SignificantUpdateAt'] = null
          }
        }
        if (!('noteSignificantUpdateAt' in entryRestrictionDetail)) {
          entryRestrictionDetail['noteSignificantUpdateAt'] = null
        }

        // If marked as isSignificantUpdate, then we try to identify the fields that were
        // marked as SignificantUpdate and set the SignificantUpdateAt datefield
        if (isSignificantUpdate) {
          for (const selected of this.saveSignificantUpdatesSelected) {
            if (selected.restrictionType !== entryRestrictionDetail.restrictionType) {
              continue
            }
            if (selected.authorityType !== entryRestrictionDetail.authority.authorityType) {
              continue
            }

            // check if the dates have significant updates
            for (const dateField of dateFields) {
              if (selected.field === dateField) {
                entryRestrictionDetail[dateField+'SignificantUpdateAt'] = nowAsIso
              }
            }

            // check if the notes have significant updates
            if (selected.field === 'note') {
              entryRestrictionDetail['noteSignificantUpdateAt'] = nowAsIso
            }

            // check if the details have significant updates
            for (let detail of entryRestrictionDetail.restrictionDetails.entries) {
              if (selected.field === detail['type']) {
                detail['significantUpdateAt'] = nowAsIso
              }
            }
          }
        }

        // transform details
        let detailJson = JSON.stringify(entryRestrictionDetail.restrictionDetails)
        this.$set(entryRestrictionDetail, "restrictionDetailJson", detailJson)

        if (isSignificantUpdate) {
          this.$set(entryRestrictionDetail, "significantUpdateAt", nowAsIso)
        }
      }

      if (isSignificantUpdate) {
        this.$set(entryRestriction, "significantUpdateAt", nowAsIso)
        this.$set(entryRestriction, "significantUpdateBy", { id: this.user.id} )
        this.$set(entryRestriction, "significantUpdateNoteDe", significantUpdateNoteDe )
        this.$set(entryRestriction, "significantUpdateNoteEn", significantUpdateNoteEn )
      }

      if (isChecked) {
        this.$set(entryRestriction, "checkedAt", nowAsIso)
        this.$set(entryRestriction, "checkedBy", { id: this.user.id} )
      }

      let gmEventCovidUpdate = null

      if (this.gmEventCovidUpdate.send) {
        gmEventCovidUpdate = {
          textDe: this.gmEventCovidUpdate.textDe,
          textEn: this.gmEventCovidUpdate.textEn,
          authorityDe: this.gmEventCovidUpdate.authorityDe,
          authorityEn: this.gmEventCovidUpdate.authorityEn,
          riskLevel: this.gmEventCovidUpdate.riskLevel,
          sources: this.gmEventCovidUpdate.sources
        }
      }

      this.$emit('save', {
          entryRestriction: entryRestriction,
          gmEventCovidUpdate: gmEventCovidUpdate
        })
    },
    openSaveDialog() {
      if (!this.validate()) {
        return
      }

      this.fillSaveItems()

      this.saveDialog = true
    },

    addGmEventCovidUpdateSource() {
      this.gmEventCovidUpdate.sources.push({
        "sourceDe": "",
        "sourceEn": "",
        "link": "",
        "descriptionDe": "",
        "descriptionEn": "",
      })
    },
    removeGmEventCovidUpdateSource(index) {
      this.gmEventCovidUpdate.sources.splice(index, 1)
    },

    fillSaveItems() {
      this.saveItems = []
      this.saveSignificantUpdatesSelected = []

      let id = 0

      let dateFields = [
        {
          'categoryI18n': "main.REGULAR_CONDITIONS",
          'field': 'noRestrictionsStart',
          'fieldName': 'Start'
        }, {
          'categoryI18n': "main.REGULAR_CONDITIONS",
          'field': 'noRestrictionsEnd',
          'fieldName': 'End'
        },

        {
          'categoryI18n': "main.NO_ACCESS_POSSIBLE",
          'field': 'noAccessStart',
          'fieldName': 'Start'
        }, {
          'categoryI18n': "main.NO_ACCESS_POSSIBLE",
          'field': 'noAccessEnd',
          'fieldName': 'End'
        },

        {
          'categoryI18n': "main.PARTIAL_RESTRICTIONS",
          'field': 'someRestrictionsStart',
          'fieldName': 'Start'
        }, {
          'categoryI18n': "main.PARTIAL_RESTRICTIONS",
          'field': 'someRestrictionsEnd',
          'fieldName': 'End'
        }
      ]

      // details
      let catOrder = 0
      for (let entryRestrictionDetail of this.entryRestrictionDetails) {
        for (let dateField of dateFields) {
          let newValue = entryRestrictionDetail[dateField.field]
          let oldValue = entryRestrictionDetail[dateField.field+'Original']
          if (newValue !== oldValue) {
            let category = 
              this.restrictionTypes[entryRestrictionDetail.restrictionType]
              + ' / ' +
              entryRestrictionDetail.authority.name
              + ' (' + catOrder.toString().padStart(3, '0') + ') / ' +
              this.$t(dateField.categoryI18n)

              this.saveItems.push({
                "id": id++,
                "cat": category, // Geschäftsreisen / Zielland / Einreisebestimmungen
                "categoryI18n": dateField.categoryI18n,
                "authorityType": entryRestrictionDetail.authority.authorityType,
                "restrictionType": entryRestrictionDetail.restrictionType,
                "field": dateField.field,
                "fieldName": dateField.fieldName,
                "old": oldValue,
                "new": newValue,
              })
          }
        }
        // note fields
        let newDeValue = entryRestrictionDetail.noteRawDe
        let oldDeValue = entryRestrictionDetail.noteRawDeOriginal
        let newEnValue = entryRestrictionDetail.noteRawEn
        let oldEnValue = entryRestrictionDetail.noteRawEnOriginal

        if (newDeValue !== oldDeValue || newEnValue !== oldEnValue) {
          let category = 
            this.restrictionTypes[entryRestrictionDetail.restrictionType]
            + ' / ' +
            entryRestrictionDetail.authority.name
            + ' (' + catOrder.toString().padStart(3, '0') + ') / ' +
            this.$t('main.FREETEXT')

          let oldData = []
          let newData = []

          if (entryRestrictionDetail.noteRawDe != entryRestrictionDetail.noteRawDeOriginal) {
            oldData.push('NoteDe: ' + entryRestrictionDetail.noteRawDeOriginal)
            newData.push('NoteDe: ' + entryRestrictionDetail.noteRawDe)
          }

          if (entryRestrictionDetail.noteRawEn != entryRestrictionDetail.noteRawEnOriginal) {
            oldData.push('NoteEn: ' + entryRestrictionDetail.noteRawEnOriginal)
            newData.push('NoteEn: ' + entryRestrictionDetail.noteRawEn)
          }

          this.saveItems.push({
            "id": id++,
            "cat": category, // Geschäftsreisen / Zielland / Einreisebestimmungen
            "categoryI18n": 'main.FREETEXT',
            "authorityType": entryRestrictionDetail.authority.authorityType,
            "restrictionType": entryRestrictionDetail.restrictionType,
            "field": 'note',
            "fieldName": this.$t('main.FREETEXT'),
            "old": oldData.join(' | '),
            "new": newData.join(' | '),
          })
        }


        let lastCategory = null
        for (const [configDetailType, detailTypeConfig] of Object.entries(this.detailTypes)) { // important for ordering
          for (let detail of entryRestrictionDetail.restrictionDetails.entries) {        

            if (configDetailType !== detail['type']) {
              continue
            }

            let detailTypeCategoryData = this.getDetailTypeCategoryData(detail['type'])

            if (lastCategory !== detailTypeCategoryData['title']) {
              // The data-table sorts the categories by name. Not what we want
              // So we add a "sorting helper" as a number to the name to enforce our ordering
              lastCategory = detailTypeCategoryData['title']
              catOrder++
            }

            let category = 
              this.restrictionTypes[entryRestrictionDetail.restrictionType]
              + ' / ' +
              entryRestrictionDetail.authority.name
              + ' (' + catOrder.toString().padStart(3, '0') + ') / ' +
              detailTypeCategoryData['title']

            let oldData = []
            let newData = []

            if (detail.status != detail.statusOriginal) {
              oldData.push('Status: ' + detail.statusOriginal)
              newData.push('Status: ' + detail.status)
            }

            if (detail.noteRawEn != detail.noteRawEnOriginal) {
              oldData.push('NoteEn: ' + detail.noteRawEnOriginal)
              newData.push('NoteEn: ' + detail.noteRawEn)
            }

            if (detail.noteRawDe != detail.noteRawDeOriginal) {
              oldData.push('NoteDe: ' + detail.noteRawDeOriginal)
              newData.push('NoteDe: ' + detail.noteRawDe)
            }

            if (detail.value != detail.valueOriginal) {
              oldData.push('Wert: ' + detail.valueOriginal)
              newData.push('Wert: ' + detail.value)
            }

            if (oldData.length > 0) {
              this.saveItems.push({
                "id": id++,
                "cat": category, // Geschäftsreisen / Zielland / Einreisebestimmungen
                "categoryI18n": detailTypeCategoryData['i18n'],
                "authorityType": entryRestrictionDetail.authority.authorityType,
                "restrictionType": entryRestrictionDetail.restrictionType,
                "field": detail['type'],
                "fieldName": detailTypeConfig['name'],
                "old": oldData.join(' | '),
                "new": newData.join(' | '),
              })
            }
          }
        }
      }
    },

    getDetailTypeCategoryData(detailType) {
      let categoryCode = this.detailTypes[detailType]['category']
      return this.detailTypeCategories[categoryCode]
    },

    translateGmEventTextToEnglish() {
      this.isTranslating = true
      coreService.translateText('de', 'en', this.gmEventCovidUpdate.textDe)
        .then((text) => {
          this.$set(this.gmEventCovidUpdate, 'textEn', text)
          this.isTranslating = false
        })
        .catch(() => {
          this.isTranslating = false
        })
    },

    significantUpdateNotes() {
      let noteDe = []
      let noteEn = []
      for (let selected of this.saveSignificantUpdatesSelected) {
        let catDe = this.$t(selected['categoryI18n'], 'de')
        let catEn = this.$t(selected['categoryI18n'], 'en')

        if (!noteDe.includes(catDe)) {
          noteDe.push(catDe)
        }
        if (!noteEn.includes(catEn)) {
          noteEn.push(catEn)
        }
      }

      this.significantUpdateNoteDe = noteDe.join(', ').substr(0, 200)
      this.significantUpdateNoteEn = noteEn.join(', ').substr(0, 200)
    },

    save() {
      if (!this.validate()) {
        return
      }

      let isSignificantUpdate = this.saveSignificantUpdatesSelected.length > 0
      this.emitSave(false, isSignificantUpdate, this.significantUpdateNoteDe, this.significantUpdateNoteEn)
    },

    checked() {
      if (!this.validate()) {
        return
      }

      this.emitSave(true, false, false, false)
    },
    cancel() {
      this.$emit('cancel')
    },
    getRestrictionDetail(type, restrictionDetails) {
      return restrictionDetails.entries.find(restrictionDetail => restrictionDetail.type == type)
    },
    applyFromBusiness(entryRestrictionDetail) {
      let fields = [
        'noRestrictionsStart',
        'noRestrictionsEnd',
        'someRestrictionsStart',
        'someRestrictionsEnd',
        'noAccessStart',
        'noAccessEnd',
        'sourceUri',
        'note',
        'noteDe',
        'noteEn',
        'noteRaw',
        'noteRawDe',
        'noteRawEn',
        'sourceUriCopyParent',
        'noRestrictionsStartCopyParent',
        'noRestrictionsEndCopyParent',
        'noAccessStartCopyParent',
        'noAccessEndCopyParent',
        'someRestrictionsStartCopyParent',
        'someRestrictionsEndCopyParent',
        'noteRawCopyParent'
      ]
      for (let copyEntryRestrictionDetail of this.localEntryRestriction.entryrestrictiondetails) {
        if (entryRestrictionDetail.authority.id == copyEntryRestrictionDetail.authority.id && copyEntryRestrictionDetail.restrictionType == 'BUSINESS') {
          for (let field of fields) {
            this.$set(entryRestrictionDetail, field, copyEntryRestrictionDetail[field])
          }
        }
      }
    },
    applyDetailsFromBusiness(entryRestrictionDetail) {
      for (let copyEntryRestrictionDetail of this.localEntryRestriction.entryrestrictiondetails) {
        if (entryRestrictionDetail.authority.id == copyEntryRestrictionDetail.authority.id && copyEntryRestrictionDetail.restrictionType == 'BUSINESS') {
          /* objects like restrictionDetails need a copy, or the reactive system would update it by reference in the ui */
          let newDetails = JSON.parse(JSON.stringify(copyEntryRestrictionDetail['restrictionDetails']))
          this.$set(entryRestrictionDetail, 'restrictionDetails', newDetails)
        }
      }
    },
    copyAllFromParent() {
      // copied the values from the parent for all fields
      // IMPORTANT: The values of the fields are copied/changed in the EntryRestrictionEditParentTextField Component!

      let copyFields = [
        'sourceUriCopyParent',
        'noRestrictionsStartCopyParent',
        'noRestrictionsEndCopyParent',
        'noAccessStartCopyParent',
        'noAccessEndCopyParent',
        'someRestrictionsStartCopyParent',
        'someRestrictionsEndCopyParent',
        'noteRawCopyParent'
      ]

      for (let entryRestrictionDetail of this.localEntryRestriction.entryrestrictiondetails) {
        for (let copyField of copyFields) {
          entryRestrictionDetail[copyField] = true
        }

        for (let detailEntry of entryRestrictionDetail.restrictionDetails.entries) {
          detailEntry['copyParent'] = true
        }
      }

    },
    getParentDetail(entryRestrictionDetail) {
      for (let parentDetail of this.localEntryRestrictionParent.entryrestrictiondetails) {
        if (parentDetail.authority.id == entryRestrictionDetail.authority.id && parentDetail.restrictionType == entryRestrictionDetail.restrictionType) {
          return parentDetail
        }
      }
      return null
    },
    getParentDetailSubDetail(entryRestrictionDetail, detail) {
      let parentDetail = this.getParentDetail(entryRestrictionDetail)

      for (let parentSubDetail of parentDetail.restrictionDetails.entries) {
        if (parentSubDetail.type == detail.type) {
          return parentSubDetail
        }
      }

      return null
    },
    changeCopyParentValue(entryRestrictionDetail, fields) {
      let parentDetail = this.getParentDetail(entryRestrictionDetail)

      for (let field of fields) {
        entryRestrictionDetail[field] = parentDetail[field]
      }
    },
    changeCopyParentDetailValue(entryRestrictionDetail, detail, fields) {
      let parentSubDetail = this.getParentDetailSubDetail(entryRestrictionDetail, detail)
      for (let field of fields) {
        detail[field] = parentSubDetail[field]
      }
    },
    getDetailParentValuePreview(entryRestrictionDetail, detail, detailType) {
      let parentSubDetail = this.getParentDetailSubDetail(entryRestrictionDetail, detail)

      let text = ''
      if (detailType == 'FREETEXT') {
        text = ''
      } else if (detailType == 'POSINT') {
        text = parentSubDetail.value
      } else {
        let statusList = []
        if (detailType == 'YESNO' || detailType == 'YESNOTEXT' ) {
          statusList = this.detailTypeYesNo
        } else {
          statusList = this.detailTypeColor
        }
        let entry = statusList.find((entry) => entry.value == parentSubDetail.status )
        if (entry) {
          text = entry.text + ' | '
        }
      }

      text = text + parentSubDetail.noteRawDe

      return text
    },
    copyAllTourismDetailsFromBusiness(entryRestrictionDetail) {
      for (let editCategory of entryRestrictionDetail.editCategories) {
        editCategory.copyFromBusiness = true
      }
    },
    isEditCategoryCopy(entryRestrictionDetail, typeCategory) {
      if (entryRestrictionDetail.restrictionType == 'BUSINESS') {
        return false
      }

      for (let editCategory of entryRestrictionDetail.editCategories) {
        if (editCategory.category == typeCategory) {
          return editCategory.copyFromBusiness
        }
      }

      return false
    }
  },
  watch: {
    entryRestrictionParent() {
      this.updateLocalEntryRestrictionParent()
    },
    entryRestriction() {
      this.updateLocalEntryRestriction()
    },
    entryRestrictionAuthorities() {
      this.updateLocalEntryRestriction()
    },
    saveSignificantUpdatesSelected() {
      this.significantUpdateNotes()
    }
  }
}
</script>

<style>
  .entryRestrictionTable .v-input__control {
    height: 28px!important;
    min-height: 28px!important;
    font-size: 14px;
  }

  .entryRestrictionTable .v-input__control label {
    font-size: 14px;
  }

  .entryRestrictionTable .v-text-field .v-input__control .v-input__slot {
    box-shadow: none!important;
    border: 1px solid #e4e4e4
  }

  .entryRestrictionTable .v-text-field .v-input__control .v-input__slot button {
    font-size: 16px;
    margin: 0!important;
  }

  .entryRestrictionTable .v-data-table__wrapper {
    overflow-x: hidden;
  }

  .entryRestrictionTable .field-changed .v-input__slot {
    background-color: #96e896!important;
  }
</style>

<style scoped>

  .entryRestrictionTable {
    border: 1px solid #bfbfbf;
  }

  .entryRestrictionTable th {
    color: black!important;
    font-size: 90%;
  }

  .v-data-table--fixed-header thead tr:nth-child(2) th {
    top: 32px;
  }

  tbody th {
    font-size: 85%;
  }

  .sub-level {
    padding-left: 2rem;
  }

  .sub-level-2 {
    padding-left: 4rem;
  }

  .tourismCol {
    background-color: #f1efef61;
  }

  .cell-main-input {
    margin-bottom: 1rem!important;
  }

  th.multi-column  {
    text-align: center;
  }

  th.separate-column-start,
  th.separate-column-middle,
  th.separate-column-end {
    background-color: #f1efef61;
  }

  .header-1 th.separate-column-start,
  .header-1 th.separate-column-end {
    background-color: #f1efef61;
  }

  td.separate-column-start,
  td.separate-column-middle,
  td.separate-column-end {
    background-color: #f1efef61;
  }

  .color-select-item {
    margin-right: 4px;
    border: 1px solid gray;
    height: 10px;
    width: 10px;
  }

  .isCidmer {
    background-color: #d9d9d9;
    color: gray;
  }
</style>
