<template>
  <div class="main-view">

    <v-btn class="refresButton" @click="doCountriesEntryRestrictionsFetchAll(true)"><v-icon class="mr-2">refresh</v-icon>Aktualisieren</v-btn>

    <h1 class="headline">Reiseeinschränkungen bearbeiten</h1>

    <v-row>
      <v-col>
        <v-text-field class="countryNameSearch" v-model="countryNameSearch" solo clearable @click:clear="countryNameSearch=''" label="Suche nach Ländernamen"/>
      </v-col>
      <v-col>
        <v-row no-gutters>
          <v-col>
            <v-select :items="dateFilterOptions" v-model="dateFilterOption" label="Zuletzt bearbeitet am" solo></v-select>
          </v-col>
          <v-col v-if="dateFilterOption != 'ALL'">
            <DatePicker v-model="dateFilterDate" label="Datum" solo />
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-simple-table class="entryRestrictions">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left"></th>
            <th class="text-left"></th>
            <th class="text-left"></th>
            <th class="text-center separate-column" colspan="2">Zuletzt bearbeitet</th>
            <th class="text-center" colspan="2">Zuletzt geprüft</th>
            <th class="text-center separate-column" colspan="3">Wichtige Änderung </th>
            <th class="text-left"></th>
            <th class="text-left"></th>
            <th class="text-left"></th>
          </tr>
          <tr>
            <th class="text-left">Land</th>
            <th class="text-left">Region</th>
            <th class="text-left">Flughafen</th>
            <th class="text-left separate-column">Datum</th>
            <th class="text-left separate-column">Von</th>
            <th class="text-left">Datum</th>
            <th class="text-left">Von</th>
            <th class="text-left separate-column">Datum</th>
            <th class="text-left separate-column">Von</th>
            <th class="text-left separate-column">Beschreibung</th>
            <th class="text-left">Erinnerung</th>
            <th class="text-left">Notiz</th>
            <th class="text-left"></th>
            <th class="text-left"></th>
          </tr>
        </thead>
        <tbody>

          <template v-for="country in filterCountries(countriesEntryRestrictions)">
            <tr  :key="'COUNTRY_'+country.id" :class="{ reminderIsOld: isReminderOld(country.entryRestriction) }">
              <td>{{ country.nameShort }}</td>
              <td></td>
              <td></td>
              <td  class="text-no-wrap separate-column" :class="{ filteredModifiedBy: isModifiedAtFilter() && testByModifiedAt(country.entryRestriction) }">
                {{ formatDateTime(country.entryRestriction.modifiedAt) }}
              </td>
              <td class="separate-column">{{ getModifiedByName(country.entryRestriction) }}</td>
              <td  class="text-no-wrap" :class="{ filteredModifiedBy: isCheckedAtFilter() && testByCheckedAt(country.entryRestriction) }">
                {{ formatDateTime(country.entryRestriction.checkedAt) }}
              </td>
              <td>{{ getCheckedByName(country.entryRestriction) }}</td>
              <td class="text-no-wrap separate-column">{{ formatDateTime(country.entryRestriction.significantUpdateAt) }}</td>
              <td class="separate-column">{{ getSignificantUpdateByName(country.entryRestriction) }}</td>
              <td class="separate-column">{{ country.entryRestriction.significantUpdateNote }}</td>
              <td class="text-no-wrap" :class="{ filteredReminderBy: isReminderFilter() && testByReminder(country.entryRestriction) }">
                {{ formatDate(country.entryRestriction.reminder) }}
              </td>
              <td>
                <TextTooltip :text="country.entryRestriction.internalNote" />
              </td>
              <td class="text-no-wrap">
                <v-btn color="primary" :to="{ name: 'entryrestriction-edit', params: { entryRestrictionId: country.entryRestriction.id } }"><v-icon>edit</v-icon></v-btn>
                <v-btn color="primary" @click="openCountryDetailView(country.id)" class="ml-2"><v-icon>web</v-icon></v-btn>
                <v-btn color="primary" @click="openAddDialog(country)" class="ml-2"><v-icon>add</v-icon></v-btn>
              </td>
              <td></td>
            </tr>
            <template v-for="region in country.regions">
              <!-- Region has an entryRestriction -->
              <tr v-if="region.entryRestriction" :key="'REGION_'+region.id" :class="{ reminderIsOld: isReminderOld(region.entryRestriction) }">
                <td></td>
                <td>{{ region.name }}</td>
                <td></td>
                <td class="separate-column" :class="{ filteredModifiedBy: isModifiedAtFilter() && testByModifiedAt(region.entryRestriction) }">
                  {{ formatDateTime(region.entryRestriction.modifiedAt) }}
                </td>
                <td class="separate-column">{{ getModifiedByName(region.entryRestriction) }}</td>
                <td  class="text-no-wrap" :class="{ filteredModifiedBy: isCheckedAtFilter() && testByCheckedAt(region.entryRestriction) }">
                  {{ formatDateTime(region.entryRestriction.checkedAt) }}
                </td>
                <td>{{ getCheckedByName(region.entryRestriction) }}</td>
                <td class="text-no-wrap separate-column">{{ formatDateTime(region.entryRestriction.significantUpdateAt) }}</td>
                <td class="separate-column">{{ getSignificantUpdateByName(region.entryRestriction) }}</td>
                <td class="separate-column">{{ region.entryRestriction.significantUpdateNote }}</td>
                <td class="text-no-wrap" :class="{ filteredReminderBy: isReminderFilter() && testByReminder(region.entryRestriction) }">
                  {{ formatDate(region.entryRestriction.reminder) }}
                </td>
                <td>
                  <TextTooltip :text="region.entryRestriction.internalNote" />
                </td>
                <td class="text-no-wrap">
                  <v-btn color="primary" :to="{ name: 'entryrestriction-edit', params: { entryRestrictionId: region.entryRestriction.id } }"><v-icon>edit</v-icon></v-btn>
                  <v-btn color="primary" @click="openRegionDetailView(region.id)" class="ml-2"><v-icon>web</v-icon></v-btn>
                </td>
                <td><v-btn color="secondary" :loading="deactivationLoading" @click="confirmeDeactivateEntryRestriction(region.entryRestriction)">Deaktivieren</v-btn></td>
              </tr>

              <!-- Region WITHOUT entryRestriction -->
              <tr  v-if="!region.entryRestriction" :key="'REGION_WITHOUT_'+region.id">
                <td></td>
                <td>{{ region.name }}</td>
                <td></td>
                <td class="separate-column" colspan="2"></td>
                <td colspan="2"></td>
                <td class="separate-column" colspan="3"></td>
                <td colspan="2"></td>
                <td><v-btn color="primary" @click="createEntryRestriction({type: 'REGION', countryId: country.id, regionId: region.id})"><v-icon>add</v-icon></v-btn></td>
                <td></td>
              </tr>

              <tr v-for="airport in region.airports" :key="'AIRPORT_'+airport.id" :class="{ reminderIsOld: isReminderOld(airport.entryRestriction) }">
                <td></td>
                <td></td>
                <td>{{ airport.iata }}</td>
                <td class="separate-column" :class="{ filteredModifiedBy: isModifiedAtFilter() && testByModifiedAt(airport.entryRestriction) }">
                  {{ formatDateTime(airport.entryRestriction.modifiedAt) }}
                </td>
                <td class="separate-column">{{ getModifiedByName(airport.entryRestriction) }}</td>
                <td>{{ getCheckedByName(airport.entryRestriction) }}</td>
                <td class="text-no-wrap" :class="{ filteredReminderBy: isReminderFilter() && testByReminder(airport.entryRestriction) }">
                  {{ formatDate(airport.entryRestriction.reminder) }}
                </td>
                <td class="text-no-wrap separate-column">{{ formatDateTime(airport.entryRestriction.significantUpdateAt) }}</td>
                <td class="separate-column">{{ getSignificantUpdateByName(airport.entryRestriction) }}</td>
                <td class="separate-column">{{ airport.entryRestriction.significantUpdateNote }}</td>
                <td class="text-no-wrap" :class="{ filteredReminderBy: isReminderFilter() && testByReminder(airport.entryRestriction) }">
                  {{ formatDate(airport.entryRestriction.reminder) }}
                  </td>
                <td>
                  <TextTooltip :text="airport.entryRestriction.internalNote" />
                </td>
                <td>
                  <v-btn color="primary" :to="{ name: 'entryrestriction-edit', params: { entryRestrictionId: airport.entryRestriction.id } }"><v-icon>edit</v-icon></v-btn>
                  <v-btn color="primary" @click="openAirportDetailView(airport.id)" class="ml-2"><v-icon>web</v-icon></v-btn>
                </td>
                <td><v-btn color="secondary" :loading="deactivationLoading" @click="confirmeDeactivateEntryRestriction(airport.entryRestriction)">Deaktivieren</v-btn></td>
              </tr>
            </template>
            <template v-if="country.airports.length > 0">
              <tr :key="'AIRPORTS_WIHTOUT_REGION_'+country.id">
                <td></td>
                <td>Flughäfen ohne Region</td>
                <td></td>
                <td class="separate-column" colspan="2"></td>
                <td colspan="2"></td>
                <td class="separate-column" colspan="3"></td>
                <td colspan="4"></td>
              </tr>
              <tr v-for="airport in country.airports" :key="'AIRPORT_SOLO_'+airport.id" :class="{ reminderIsOld: isReminderOld(airport.entryRestriction) }">
                <td></td>
                <td></td>
                <td>{{ airport.iata }}</td>
                <td class="separate-column" :class="{ filteredModifiedBy: isModifiedAtFilter() && testByModifiedAt(airport.entryRestriction) }">
                  {{ formatDateTime(airport.entryRestriction.modifiedAt) }}
                </td>
                <td class="separate-column">{{ getModifiedByName(airport.entryRestriction) }}</td>
                <td>{{ getCheckedByName(airport.entryRestriction) }}</td>
                <td class="text-no-wrap" :class="{ filteredReminderBy: isReminderFilter() && testByReminder(airport.entryRestriction) }">
                  {{ formatDate(airport.entryRestriction.reminder) }}
                </td>
                <td class="text-no-wrap separate-column">{{ formatDateTime(airport.entryRestriction.significantUpdateAt) }}</td>
                <td class="separate-column">{{ getSignificantUpdateByName(airport.entryRestriction) }}</td>
                <td class="separate-column">{{ airport.entryRestriction.significantUpdateNote }}</td>
                <td class="text-no-wrap" :class="{ filteredReminderBy: isReminderFilter() && testByReminder(airport.entryRestriction) }">
                  {{ formatDate(airport.entryRestriction.reminder) }}
                </td>
                <td>
                  <TextTooltip :text="airport.entryRestriction.internalNote" />
                </td>
                <td>
                  <v-btn color="primary" :to="{ name: 'entryrestriction-edit', params: { entryRestrictionId: airport.entryRestriction.id } }"><v-icon>edit</v-icon></v-btn>
                  <v-btn color="primary" @click="openAirportDetailView(airport.id)" class="ml-2"><v-icon>web</v-icon></v-btn>
                </td>
                <td><v-btn color="secondary" :loading="deactivationLoading" @click="confirmeDeactivateEntryRestriction(airport.entryRestriction)">Deaktivieren</v-btn></td>
              </tr>
            </template>
          </template>

        </tbody>
      </template>
    </v-simple-table>

    <v-dialog v-model="addDialogOpen" max-width="1000">
      <v-card>
        <v-card-title>
          Bitte wählen
        </v-card-title>
        <v-card-text>
          <CreateRegionOrAirportEntryRestriction v-if="addDialogCountry" :countryId="addDialogCountry.id" @select="entryRestrictionGeoSelected" @cancel="addDialogOpen=false" />
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>

import { mapState, mapActions, mapMutations } from "vuex"
import moment from "moment"
import DatePicker from "@/components/ui/DatePicker";
import CreateRegionOrAirportEntryRestriction from "@/components/entryrestriction/edit/CreateRegionOrAirportEntryRestriction";
import TextTooltip from "@/components/ui/TextTooltip";

export default {
  name: 'EntryRestrictionsView',
  data() {
    return {
      deactivationLoading: false, // handles the loading animation for the "Deaktivieren" Buttons
      dateFilterOptions: [
        {value: "ALL", text: "Datumsfilter wählen..."},
        {value: "MODIFIED_AT_LT", text: "Zuletzt bearbeitet: Datum größer/gleich als"},
        {value: "MODIFIED_AT_GT", text: "Zuletzt bearbeitet: Datum kleiner/gleich als"},
        {value: "REMINDER_LT", text: "Erinnerung: Datum größer/gleich als"},
        {value: "REMINDER_GT", text: "Erinnerung: Datum kleiner/gleich als"},
        {value: "CHECKED_AT_LT", text: "Zuletzt geprüft: Datum größer/gleich als"},
        {value: "CHECKED_AT_GT", text: "Zuletzt geprüft: Datum kleiner/gleich als"},
      ],
    }
  },
  mounted: function() {
    this.doCountriesEntryRestrictionsFetchAll(true)
  },
  components: {
    DatePicker,
    CreateRegionOrAirportEntryRestriction,
    TextTooltip
  },
  computed: {
    ...mapState({
      countriesEntryRestrictions: state => state.countriesEntryRestrictionsStore.entries,
      countriesEntryRestrictionsIsLoading: state => state.countriesEntryRestrictionsStore.isLoading,
    }),
    ...mapState([
      'entryRestrictionsViewFilters',
    ]),

    countryNameSearch: {
      get: function() { return this.entryRestrictionsViewFilters.countryNameSearch },
      set: function(newValue) {
        this.entryRestrictionsViewFilters.countryNameSearch = newValue
        this.SET_ENTRYRESTRICTIONSVIEWFILTERS(this.entryRestrictionsViewFilters)
       }
    },
    dateFilterOption: {
      get: function() { return this.entryRestrictionsViewFilters.dateFilterOption },
      set: function(newValue) {
        this.entryRestrictionsViewFilters.dateFilterOption = newValue
        this.SET_ENTRYRESTRICTIONSVIEWFILTERS(this.entryRestrictionsViewFilters)
       }
    },
    dateFilterDate: {
      get: function() { return this.entryRestrictionsViewFilters.dateFilterDate },
      set: function(newValue) {
        this.entryRestrictionsViewFilters.dateFilterDate = newValue
        this.SET_ENTRYRESTRICTIONSVIEWFILTERS(this.entryRestrictionsViewFilters)
       }
    },
    addDialogOpen: {
      get: function() { return this.entryRestrictionsViewFilters.addDialogOpen },
      set: function(newValue) {
        this.entryRestrictionsViewFilters.addDialogOpen = newValue
        this.SET_ENTRYRESTRICTIONSVIEWFILTERS(this.entryRestrictionsViewFilters)
       }
    },
    addDialogCountry: {
      get: function() { return this.entryRestrictionsViewFilters.addDialogCountry },
      set: function(newValue) {
        this.entryRestrictionsViewFilters.addDialogCountry = newValue
        this.SET_ENTRYRESTRICTIONSVIEWFILTERS(this.entryRestrictionsViewFilters)
       }
    },

    countriesEntryRestrictionsFiltered() {
      let search = this.countryNameSearch
      if (!search) {
        search = ''
      }
      search = search.toLowerCase()
      return this.countriesEntryRestrictions.filter(item => item.nameShort.toLowerCase().indexOf(search) > -1)
    }
  },
  methods: {
    ...mapMutations([
      'SET_ENTRYRESTRICTIONSVIEWFILTERS'
    ]),
    ...mapActions({
      countriesEntryRestrictionssFetchAll: 'countriesEntryRestrictionsStore/fetchAll',
      entryRestrictionsFetchAll: 'entryRestrictionsStore/fetchAll',
      entryRestrictionFetch: 'entryRestrictionStore/fetch', // used to ensure we can safely create an EntryRestriction
      entryRestrictionCreate: 'entryRestrictionStore/create',
      entryRestrictionUpdate: 'entryRestrictionStore/update',
    }),

    doCountriesEntryRestrictionsFetchAll(force) {
      if (force === undefined) {
        force = false
      }
      this.countriesEntryRestrictionssFetchAll({force: force}).then(() => {
        // reset loading animations
        this.deactivationLoading = false
      })
    },

    isModifiedAtFilter() {
      if (this.dateFilterOption == 'ALL' || (this.dateFilterOption != 'MODIFIED_AT_LT' && this.dateFilterOption != 'MODIFIED_AT_GT')) {
        return false
      }
      return true
    },

    testByModifiedAt(entryRestriction) {
      if (!entryRestriction) {
        return false
      }
      if (!this.isModifiedAtFilter()) {
        return true
      }

      if (!entryRestriction.__modifiedAtDay) {
        entryRestriction.__modifiedAtDay = moment(entryRestriction.modifiedAt).startOf('day')
      }

      let dateFilterDate = moment(this.dateFilterDate).startOf('day')
      if (this.dateFilterOption == 'MODIFIED_AT_LT' && entryRestriction.__modifiedAtDay >= dateFilterDate) {
        return true
      }
      if (this.dateFilterOption == 'MODIFIED_AT_GT' && entryRestriction.__modifiedAtDay <= dateFilterDate) {
        return true
      }

      return false
    },

    isReminderFilter() {
      if (this.dateFilterOption == 'ALL' || (this.dateFilterOption != 'REMINDER_LT' && this.dateFilterOption != 'REMINDER_GT')) {
        return false
      }
      return true
    },

    testByReminder(entryRestriction) {
      if (!entryRestriction) {
        return false
      }
      if (!this.isReminderFilter()) {
        return true
      }

      if (!entryRestriction.reminder || entryRestriction.reminder == '') {
        return false
      }

      if (!entryRestriction.__reminderDay) {
        entryRestriction.__reminderDay = moment(entryRestriction.reminder).startOf('day')
      }

      let dateFilterDate = moment(this.dateFilterDate).startOf('day')
      if (this.dateFilterOption == 'REMINDER_LT' && entryRestriction.__reminderDay >= dateFilterDate) {
        return true
      }
      if (this.dateFilterOption == 'REMINDER_GT' && entryRestriction.__reminderDay <= dateFilterDate) {
        return true
      }

      return false
    },

    isCheckedAtFilter() {
      if (this.dateFilterOption == 'ALL' || (this.dateFilterOption != 'CHECKED_AT_LT' && this.dateFilterOption != 'CHECKED_AT_GT')) {
        return false
      }
      return true
    },

    testByCheckedAt(entryRestriction) {
      if (!entryRestriction) {
        return false
      }
      if (!this.isCheckedAtFilter()) {
        return true
      }

      if (!entryRestriction.checkedAt || entryRestriction.checkedAt == '') {
        return false
      }

      if (!entryRestriction.__checkedAt) {
        entryRestriction.__checkedAt = moment(entryRestriction.checkedAt).startOf('day')
      }

      let dateFilterDate = moment(this.dateFilterDate).startOf('day')
      if (this.dateFilterOption == 'CHECKED_AT_LT' && entryRestriction.__checkedAt >= dateFilterDate) {
        return true
      }
      if (this.dateFilterOption == 'CHECKED_AT_GT' && entryRestriction.__checkedAt <= dateFilterDate) {
        return true
      }

      return false
    },

    filterRegions(regions, search) {
      return regions.filter(region => this.testByModifiedAt(region.entryRestriction) && this.testByReminder(region.entryRestriction) && this.testByCheckedAt(region.entryRestriction))
    },

    filterAirports(airports, search) {
      return airports.filter(airport => this.testByModifiedAt(airport.entryRestriction) && this.testByReminder(airport.entryRestriction) && this.testByCheckedAt(airport.entryRestriction))
    },

    filterCountries(countries) {
      let search = this.countryNameSearch
      if (!search) {
        search = ''
      }
      search = search.toLowerCase()

      return countries.filter(country => {
        // cache lowercase version for speed reasons
        if (!country.__nameShort__LowerCase) {
          country.__nameShort__LowerCase = country.nameShort.toLowerCase()
        }

        let show = true
        // Main check:
        // If a search by name is defined, it WILL filter the countries
        // All other searches are optional and build on top of that
        // If NO search by name was defined, the other searches can filter the full list.
        if (search != '') {
          show = country.__nameShort__LowerCase.toLowerCase().indexOf(search) > -1
        }

        // If the entry was NOT filtered by the search above, we can filter the entry "more"
        if (show) {
          show = this.testByModifiedAt(country.entryRestriction) && this.testByReminder(country.entryRestriction) && this.testByCheckedAt(country.entryRestriction)

          // A negative date filter on country can be overwritten by positive filters on regions and country airports
          // Means: If an airport should be shown because of the date filters, the country must also be shown. But that will NOT
          //        show a country that was filtered by search name
          if (!show) {
            show = this.filterRegions(country.regions, search).length > 0 || this.filterAirports(country.airports, search).length > 0

            // and at last, region airports and also checked
            if (!show) {
              for (let region of country.regions) {
                if (region.airports && this.filterAirports(region.airports, search).length > 0) {
                  show = true
                }
              }
            }
          }
        }

        return show
      })
    },

    confirmeDeactivateEntryRestriction(entryRestriction) {
      this.$confirm('Möchten Sie diesen Eintrag wirklich deaktivieren?').then((res) => {
        if (res) {
          this.deactivateEntryRestriction(entryRestriction)
        }
      })
    },

    deactivateEntryRestriction(entryRestriction) {
      this.deactivationLoading = true
      this.entryRestrictionUpdate({
        variables: {
          input: {
            id: entryRestriction.id,
            isActive: false
          }
        }
      }).then(() => {
        this.doCountriesEntryRestrictionsFetchAll(true)
      })
    },

    getModifiedByName(entryRestriction) {
      if (!entryRestriction.modifiedBy) {
        return ''
      }

      return [entryRestriction.modifiedBy.firstName, entryRestriction.modifiedBy.lastName].join(' ')
    },

    getCheckedByName(entryRestriction) {
      if (!entryRestriction.checkedBy) {
        return ''
      }

      return [entryRestriction.checkedBy.firstName, entryRestriction.checkedBy.lastName].join(' ')
    },

    getSignificantUpdateByName(entryRestriction) {
      if (!entryRestriction.significantUpdateBy) {
        return ''
      }

      return [entryRestriction.significantUpdateBy.firstName, entryRestriction.significantUpdateBy.lastName].join(' ')
    },

    formatDateTime(dateTime) {
      let momentDate = moment(dateTime)
      if (!momentDate.isValid()) {
        return ''
      }
      return momentDate.format('DD.MM.YYYY HH:mm')
    },

    formatDate(dateTime) {
      if (!dateTime || dateTime == '') {
        return ''
      }
      return moment(dateTime).format('DD.MM.YYYY')
    },

    openAddDialog(country) {
      this.addDialogCountry = country
      this.addDialogOpen = true
    },

    entryRestrictionGeoSelected(data) {
      this.addDialogOpen = false
      this.createEntryRestriction(data)
    },

    createEntryRestriction(data) {
      // first, we test if there is an existing EntryRestriction for this geo entry.
      // we directly use apollo here, as we don't need any loading status or caches here
      let variables = {
        restrictionLevel: data.type,
      }
      // we don't want to expose unwanted variables in the query, so we assign them directly
      if (data.type == 'REGION') {
        variables['regionId'] = data.regionId
      }
      if (data.type == 'AIRPORT') {
        variables['airportId'] = data.airportId
      }

      this.entryRestrictionsFetchAll({
        variables: variables,
        fetchOnly: true // do not update the store, only return the entries
      }).then((entries) => {
        if (entries.length == 0) {
          // None found == we create it and route to the editor
          this.entryRestrictionCreate({
            variables: {
              input: {
                restrictionLevel: data.type,
                country: data.countryId,
                region: data.regionId,
                airport: data.airportId
              }
            }
          }).then((entry) => {
            this.$router.push({ name: 'entryrestriction-edit', params: { entryRestrictionId: entry.id } })
          })

        } else {
          // Found == open existing entry
          let entryRestrictionId = entries[0].id
          this.$router.push({ name: 'entryrestriction-edit', params: { entryRestrictionId: entryRestrictionId } })
        }

      })
    },
    openCountryDetailView(countryId) {
      let url = this.$router.resolve({ name: 'destination-country-detail', params: { countryId: countryId } })
      window.open(url.href)
    },
    openRegionDetailView(regionId) {
      let url = this.$router.resolve({ name: 'destination-region-detail', params: { regionId: regionId } })
      window.open(url.href)
    },
    openAirportDetailView(airportId) {
      let url = this.$router.resolve({ name: 'destination-airport-detail', params: { airportId: airportId } })
      window.open(url.href)
    },
    isReminderOld(entryRestriction) {
      if (!entryRestriction.__reminderDay) {
        entryRestriction.__reminderDay = moment(entryRestriction.reminder).startOf('day')
      }
      if (entryRestriction.__reminderDay <= moment().startOf('day')) {
        return true
      }
      return false
    }
  },

}
</script>

<style scoped>
  .main-view {
      padding: 2rem;
      max-width: 2000px;
      margin: auto;
  }

  .entryRestrictions th {
    color: black!important;
    font-size: 90%;
  }


  .refresButton {
    float: right;
  }

  .loadingBlock {
    margin-top: 1rem;
    clear: both;
    height: 0.5rem;
  }

  .countryNameSearch {
    max-width: 50%;
    min-width: 200px;
  }

  .filteredModifiedBy, .filteredReminderBy {
    font-weight: bold;
  }

  .separate-column {
    background-color: #f1efef80;
  }

  .reminderIsOld {
    color: red;
  }
</style>
