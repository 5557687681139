<template>
  <v-tooltip bottom v-if="text">
    <template v-slot:activator="{ on }">
      <v-icon color="primary" dark v-on="on">note</v-icon>
    </template>
    <span v-if="title">{{ title }}</span>
    <span v-if="!allowHtml">{{ text }}</span>
    <span v-if="allowHtml" v-html="text" />
  </v-tooltip>
</template>

<script>
export default {
  name: "TextTooltip",
  props: {
    text: {
      type: String
    },
    title: {
      type: String
    },
    allowHtml: {
      type: Boolean,
      default: false
    }
  }
}
</script>