<template>
  <span>
    <v-text-field
      v-model="text"
      :append-icon="appendIcon"
      :label="label"
      :solo="solo"
      :dense="dense"
      @click:append="openDialog()"
      />
    <v-dialog v-model="isDialogOpen" width="1000">
      <v-card>
        <v-card-title>{{ $t("main.EDIT_TEXT") }}</v-card-title>
        <v-card-text>
          <v-textarea v-model="text" />
          <v-btn color="primary" @click="closeDialog()">{{ $t("main.CLOSE_ACTION") }}</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import moment from "moment"

export default {
  name: 'TextFieldWithDialog',
  props: {
    "value": {
      default: ''
    }, // v-model...
    "label": {
      type: String,
      default: ""
    },
    "appendIcon": {
      type: String,
      default: "edit"
    },
    "dense": {
      type: Boolean
    },
    "solo": {
      type: Boolean
    }
  },
  data() {
    return {
      text: null,
      isDialogOpen: false
    }
  },
  methods: {
    openDialog() {
      this.isDialogOpen = true
    },
    closeDialog() {
      this.isDialogOpen = false
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newVal) {
        this.text = newVal
      },
    },
    text: {
      handler(newVal, oldVal) {
        this.$emit('input', newVal)
      },
    }
  }
}

</script>
