<template>
  <div>
    <div v-if="country && country.data">
      <p>Land: {{ country.data.nameShort }}</p>

      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th>Region</th>
              <th>Flughäfen</th>
              <th>Aktion</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="region in country.data.regions">
              <tr :key="'Region_'+region.id">
                <td class="text-no-wrap">{{ region.name }}</td>
                <td></td>
                <td><v-btn color="primary" @click="select('REGION', country, region, null)">Hinzufügen</v-btn></td>
              </tr>
              <tr v-if="region.airports && region.airports.length > 0" :key="'RegionAirports_'+region.id">
                <td></td>
                <td>
                  <v-row>
                    <v-col cols="2" v-for="airport in region.airports" :key="'AIRPORT_'+airport.id">
                      <v-btn color="primary" @click="select('AIRPORT', country, region, airport)">{{ airport.iata }}</v-btn>
                    </v-col>
                  </v-row>
                </td>
                <td></td>
              </tr>
            </template>
            <tr v-if="country.data.airports && country.data.airports.length > 0">
              <td class="text-no-wrap">Flughäfen ohne Region</td>
              <td></td>
              <td></td>
            </tr>
            <tr v-if="country.data.airports && country.data.airports.length > 0">
              <td></td>
              <td>
                <v-row>
                  <v-col cols="2" v-for="airport in country.data.airports" :key="'AIRPORT_'+airport.id">
                    <v-btn color="primary" @click="select('AIRPORT', country, null, airport)">{{ airport.iata }}</v-btn>
                  </v-col>
                </v-row>
              </td>
              <td></td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <p class="text-right">
        <v-btn color="secondary" @click="$emit('cancel')">Abbrechen</v-btn>
      </p>

    </div>
    <div v-if="country && country.isLoading">
      <v-progress-circular
      indeterminate
      color="primary"
    ></v-progress-circular>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"

export default {
  name: 'CreateRegionOrAirportEntryRestriction',
  props: {
    "countryId": {}
  },
  mounted() {
    this.doCountryFetch()
  },
  computed: {
    ...mapGetters({
      countryGetById: 'countryStore/getById'
    }),
    country() {
      return this.countryGetById(this.countryId)
    },
  },
  methods: {
    ...mapActions({
      countryFetch: 'countryStore/fetch',
    }),
    doCountryFetch() {
      this.countryFetch({ variables: { id: this.countryId } })
    },
    select(type, country, region, airport) {
      let data = {
        type: type,
        countryId: country ? country.id : null,
        regionId:  region ? region.id : null,
        airportId: airport ? airport.id : null,
      }
      this.$emit('select', data)
    }
  },
  watch: {
    countryId() {
      this.doCountryFetch()
    }
  }
}

</script>
