<template>
  <span>
    <v-text-field
      v-model="de"
      :append-icon="dynamicAppendIcon"
      :label="label"
      :solo="solo"
      :dense="dense"
      :readonly="true"
      :class="{ 'disabled-text-color': disabled } "
      color="success"
      @click="openDialog()"
      @click:append="openDialog()"
      />
    <v-dialog v-model="isDialogOpen" width="1000">
      <v-card>
        <v-card-title>
          <span v-if="!disabled">{{ $t('main.EDIT_HTML_TEXT') }}</span>
          <span v-if="disabled">HTML Text</span>
        </v-card-title>
        <v-card-text>
          <div>Deutsch</div>
          <quill-editor v-if="!disabled" v-model="content['de']" />
          <div v-if="disabled" v-html="content['de']"></div>

          <div class="mt-5">
            English
            <v-btn
              v-if="!disabled"
              color="secondary"
              class="ml-5 mb-2"
              small
              :loading="isTranslating"
              @click="translateEnglish"
              >DE -&gt; EN</v-btn>
            <quill-editor v-if="!disabled"  v-model="content['en']" />
            <div v-if="disabled" v-html="content['en']"></div>
          </div>

          <div class="text-right">
            <v-btn color="primary" @click="closeDialog()" class="mt-2">Schließen</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import moment from "moment"
import coreService from '../../services/CoreService'

export default {
  name: 'TranslatableHtmlTextFieldWithDialog',
  props: {
    de: {
      type: String,
      default: ""
    },
    en: {
      type: String,
      default: ""
    },

    baseLanguage: {
      default: "de"
    },
    label: {
      type: String,
      default: ""
    },
    appendIcon: {
      type: String,
      default: "edit"
    },
    dense: {
      type: Boolean
    },
    solo: {
      type: Boolean
    },
    disabled: {
      type: Boolean
    }
  },
  data() {
    return {
      content: {},
      languages: [
        "de",
        "en"
      ],
      isTranslating: false,
      isDialogOpen: false,
    }
  },
  computed: {
    dynamicAppendIcon() {
      if (this.disabled) {
        return 'info'
      }

      return this.appendIcon
    }
  },
  methods: {
    openDialog() {
      this.isDialogOpen = true
    },
    closeDialog() {
      this.isDialogOpen = false
    },
    translateEnglish() {
      this.isTranslating = true
      coreService.translateText('de', 'en', this.content['de'])
        .then((text) => {
          this.$set(this.content, 'en', text)
          this.isTranslating = false
        })
        .catch(() => {
          this.isTranslating = false
        })
    }
  },
  watch: {
    de: {
      handler(newVal, oldVal) {
        this.$set(this.content, 'de', newVal)
      },
      immediate: true,
    },
    en: {
      handler(newVal, oldVal) {
        this.$set(this.content, 'en', newVal)
      },
      immediate: true,
    },
    /*
    'content.de': {
      handler(newVal, oldVal) {
        this.$emit('input-de', newVal)
      }
    },
    'content.en': {
      handler(newVal, oldVal) {
        this.$emit('input-en', newVal)
      }
    }
    */
    isDialogOpen(newVal, oldVal) {
      this.$emit('input-de', this.content['de'])
      this.$emit('input-en', this.content['en'])
    }
  }
}

</script>

<style scoped>
  .disabled-text-color >>> .v-text-field__slot input {
    color: rgba(0, 0, 0, 0.38);
  }
</style>